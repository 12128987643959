import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import Icon from './../Icon';

const TextLink = ({ to, text, onClick, icon, disabled }) => {
  function renderIcon() {
    return <Icon type={icon} size="big" color={disabled ? 'default' : ''} />;
  }

  function renderTextAndIcon() {
    return (
      <span onClick={disabled ? null : onClick} className={clsx('text-link-component', { 'text-link-component--disabled': disabled })}>
        {icon && renderIcon()}
        {text}
      </span>
    );
  }

  return (
    <>
      {to && !disabled && (
        <Link to={to} className="text-link-component">
          {renderTextAndIcon()}
        </Link>
      )}
      {(onClick || disabled) && renderTextAndIcon()}
    </>
  );
};

TextLink.propTypes = {
  icon: PropTypes.string,
};

export default TextLink;
