import { isEdelvivesMX } from '_core';
import { useTranslate } from '_core/hooks/useTranslate';

const useDialogLoginStage = () => {
  const { t } = useTranslate();
  let stages = [];
  if (isEdelvivesMX()) {
    stages = [
      {
        name: t('selector-login:Preschool and Secondary'),
        guid: 3,
      },
      {
        name: t('selector-login:Primary school and High school'),
        guid: 4,
      },
    ];
  } else {
    stages = [
      {
        name: t('selector-login:Childish'),
        guid: 1,
      },
      {
        name: t('selector-login:Primary school'),
        guid: 2,
      },
      {
        name: t('selector-login:Secondary school'),
        guid: 3,
      },
      {
        name: t('selector-login:High school'),
        guid: 4,
      },
      {
        name: t('selector-login:FP'),
        guid: 5,
      },
    ];
  }

  return { t, stages };
};

export default useDialogLoginStage;
