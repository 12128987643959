import { isEdelvivesMX } from '_core';
import { useState } from 'react';

export default function useLoginPage() {
  const [showStageDialog, setShowStageDialog] = useState(true);

  function onSelectStage(stage) {
    if (stage.guid === 1) {
      window.open('https://edelvivesdigital.com/', '_self');
    } else if (stage.guid === 3 && isEdelvivesMX()) {
      window.open('https://edelvivesdigital.com.mx/', '_self');
    } else {
      setShowStageDialog(false);
    }
  }

  return { showStageDialog, onSelectStage };
}
