import { timesUnitIds } from '_core/variables/constant';
import { defaultConfig } from './environment';

export const NEED_DEMO_GROUP = false;

export const DEFAULT_LANG = 'es';

export const MAX_FILE_UPLOAD_SIZE = 200; // size in megabytes

export const DOMAINS = {
  DEV: 'https://edelvivesdigital-dev.oneclicklabs.es/',
  QA: 'https://edelvivesdigital-qa.oneclicklabs.es/',
  PROD: 'https://edelvivesdigitalplus.com/',
};

export const clientIdGoogle = {
  'http://localhost:3003': '844406521272-c37gjlabauua8etsqvtrf6oufjodm043.apps.googleusercontent.com',
  'http://localhost:3001': '844406521272-c37gjlabauua8etsqvtrf6oufjodm043.apps.googleusercontent.com1',
  'http://localhost:3002': '844406521272-c37gjlabauua8etsqvtrf6oufjodm043.apps.googleusercontent.com',
  'https://edelvivesdigital-dev.oneclicklabs.es': '844406521272-c37gjlabauua8etsqvtrf6oufjodm043.apps.googleusercontent.com',
  'https://edelvivesdigital-mx-dev.oneclicklabs.es': '844406521272-c37gjlabauua8etsqvtrf6oufjodm043.apps.googleusercontent.com',
  'https://edelvivesdigital-qa.oneclicklabs.es': '844406521272-c37gjlabauua8etsqvtrf6oufjodm043.apps.googleusercontent.com',
  'https://edelvivesdigitalplus.com': '733400687741-2r5msegkj6s1rd5ku03hjlrgpoelgptl.apps.googleusercontent.com',
  'https://edelvivesdigitalplus.com.mx': '733400687741-2r5msegkj6s1rd5ku03hjlrgpoelgptl.apps.googleusercontent.com',
  'https://teams.microsoft.com': '733400687741-2r5msegkj6s1rd5ku03hjlrgpoelgptl.apps.googleusercontent.com',
};

export const polpApiUrl = {
  'http://localhost:3003': 'https://oraculo-dev.oneclicklabs.es/auth',
  'http://localhost:3001': 'https://oraculo-dev.oneclicklabs.es/auth',
  'http://localhost:3002': 'https://oraculo-dev.oneclicklabs.es/auth',
  'https://edelvivesdigital-dev.oneclicklabs.es': 'https://oraculo-dev.oneclicklabs.es/auth',
  'https://edelvivesdigital-mx-dev.oneclicklabs.es': 'https://oraculo-dev.oneclicklabs.es/auth',
  'https://edelvivesdigital-qa.oneclicklabs.es': 'https://oraculo-dev.oneclicklabs.es/auth',
  'https://edelvivesdigitalplus.com': 'https://oraculo-polp.oneclick.es/auth',
  'https://edelvivesdigitalplus.com.mx': 'https://oraculo-polp.oneclick.es/auth',
  'https://edelvivesdigitalplus-pre.oneclicklabs.es': 'https://oraculo-polp.oneclick.es/auth',
};

export const polpAppID = {
  'http://localhost:3003': '1726090626331533',
  'http://localhost:3001': '1726090626331533',
  'http://localhost:3002': '1726090626331533',
  'https://edelvivesdigital-dev.oneclicklabs.es': '1726090626331533',
  'https://edelvivesdigital-qa.oneclicklabs.es': '1726090626331533',
  'https://edelvivesdigitalplus.com': '1728082291326889',
  'https://edelvivesdigitalplus.com.mx': '1792209333491928',
  'https://edelvivesdigitalplus-pre.oneclicklabs.es': '1728082291326889',
  'https://edelvivesdigital-mx-dev.oneclicklabs.es': '1792209260863271',
};

export const googleAPIkey = '';
export const clientIdMicrosoft = {
  'http://localhost:3001': '5e971f22-b8fc-4757-8bf4-bdaa2ca06db1',
  'http://localhost:3002': '5e971f22-b8fc-4757-8bf4-bdaa2ca06db1',
  'http://localhost:3003': '305cd837-78cb-466f-aecc-f81e8245c9c6',
  'https://localhost:3003': '305cd837-78cb-466f-aecc-f81e8245c9c6',
  'https://edelvivesdigital-dev.oneclicklabs.es': '305cd837-78cb-466f-aecc-f81e8245c9c6',
  'https://edelvivesdigital-qa.oneclicklabs.es': '305cd837-78cb-466f-aecc-f81e8245c9c6',
  'https://edelvivesdigitalplus.com': '5dc5aa0f-98ce-457e-9332-6949a25aeee8',
  'https://edelvivesdigitalplus.com.mx': '8e491920-21af-4210-a528-22ac3532350b',
};
export const clientIdAmplitude = {
  'http://localhost:3003': '3a0c9dfec9efe51c5c610b6da0d475a5',
  'https://edelvivesdigital-dev.oneclicklabs.es': '3a0c9dfec9efe51c5c610b6da0d475a5',
  'https://edelvivesdigitalplus.com': 'd7a7d7d4541c149fdfdcf3ea24aafd74',
  'https://edelvivesdigitalplus.com.mx': 'd7a7d7d4541c149fdfdcf3ea24aafd74',
};

export const supportUrl = {
  es: 'https://ayudaonline.edelvives.es/es/formulariosd',
  en: 'https://ayudaonline.edelvives.es/es/formulariosd',
  eu: 'https://ayudaonline.edelvives.es/es/formulariosd',
  gl: 'https://ayudaonline.edelvives.es/es/formulariosd',
  vl: 'https://ayudaonline.edelvives.es/es/formulariosd',
  ca: 'https://ayudaonline.edelvives.com/ca/formulariosd',
};

// export const tutorialsUrl = 'https://www.edelvives.com/es/funcionalidades-edelvives-digital-plus';
export const tutorialsUrl = {
  ca: 'https://www.edelvives.com/es/funcionalidades-edelvives-digital-plus-cat',
  es: 'https://www.edelvives.com/es/funcionalidades-edelvives-digital-plus',
  eu: 'https://www.edelvives.com/es/funcionalidades-edelvives-digital-plus-eus',
  gl: 'https://www.edelvives.com/es/funcionalidades-edelvives-digital-plus',
  vl: 'https://www.edelvives.com/es/funcionalidades-edelvives-digital-plus',
  en: 'https://www.edelvives.com/es/funcionalidades-edelvives-digital-plus',
  pt: 'https://www.edelvives.com/es/funcionalidades-edelvives-digital-plus',
  mx: 'https://www.edelvives.com/es/funcionalidades-edelvives-digital-plus',
};

export const timesUnit = [
  {
    name: timesUnitIds.quarterly,
    periods: [
      { name: 'Quarter 1', id: 'quarter1' },
      { name: 'Quarter 2', id: 'quarter2' },
      { name: 'Quarter 3', id: 'quarter3' },
    ],
  },
];

export const INTERACTIVE_MAP_ID_CONTENT = {
  DEV: 'https://files-public.edelvivesdigital-dev.oneclickaws.tk/8ba9a820-be8a-11ee-91cd-b9119e12e045/index.html',
  QA: 'https://edelvivesdigital-qa-content.s3.eu-central-1.amazonaws.com/5852f6d0-c68b-11ee-8c27-69239d6b1d49/index.html',
  PROD: 'https://edelvivesdigitalplus-prod-content.s3.eu-central-1.amazonaws.com/b29f41c0-cf32-11ee-a842-9366a71582fd/index.html',
};

export const getInteractiveMapUrl = () => {
  const domain = defaultConfig.BASE_URL_;
  switch (domain) {
    case DOMAINS.DEV:
      return INTERACTIVE_MAP_ID_CONTENT.DEV;
    case DOMAINS.QA:
      return INTERACTIVE_MAP_ID_CONTENT.QA;
    case DOMAINS.PROD:
      return INTERACTIVE_MAP_ID_CONTENT.PROD;
    default:
      return INTERACTIVE_MAP_ID_CONTENT.PROD;
  }
};

export const GLOBAL_EDUCA_URL = {
  DEV: 'https://cuadernoedp.globaleduca.es/',
  QA: 'https://cuadernoedp.globaleduca.es/',
  PROD: 'https://cuadernoedp.globaleduca.es/',
};

export const getGlobalEducapUrl = () => {
  const domain = defaultConfig.BASE_URL_;
  switch (domain) {
    case DOMAINS.DEV:
      return GLOBAL_EDUCA_URL.DEV;
    case DOMAINS.QA:
      return GLOBAL_EDUCA_URL.QA;
    case DOMAINS.PROD:
      return GLOBAL_EDUCA_URL.PROD;
    default:
      return GLOBAL_EDUCA_URL.PROD;
  }
};

export const IPAD_INCOMPATIBLE_GAMES = ['1bb76090-0390-11ed-b02e-a19605235117', '644f6ca0-244a-11ed-92dc-4bf760042c54', 'd9884030-bf1f-11ed-a75c-33cad214f1d1'];

export const DEMO_GROUP_PAYLOAD = {
  limit_courses: 0,
  limit_courses_jobs: 0,
  name: 'Mi clase demo',
  students: [
    /*{
      username: 'student01@oneclick.es',
    },
    {
      username: 'student02@oneclick.es',
    },
    {
      username: 'student03@oneclick.es',
    },*/
    /*{
      username: 'juan.student@oneclick.es',
    },*/
    {
      username: 'fran.student@oneclick.es',
    },
  ],
  messages: [
    {
      from: 'teacher',
      message:
        'Buenos días, chicos, ¡bienvenidos a este nuevo curso! Espero que hayáis cargado las pilas estas vacaciones y vengáis con ganas de aprender muchas cosas nuevas. Este año quiero que os lo paséis genial y aprendáis mucho, así que decidme, ¿qué os apetecería hacer entre todos?',
      responses: [
        {
          from: 'student03@oneclick.es',
          message: '¡Este libro tiene muchas cosas, me encanta la portada! ¡Dan ganas de ir a un festival!',
        },
        {
          from: 'student02@oneclick.es',
          message: 'Me han dicho que tiene juegos súper chulos, ¡tengo muchas ganas de probarlos!',
        },
        {
          from: 'student01@oneclick.es',
          message: '¿Vamos a tener que hacer proyectos?, ¿cuándo empezamos?',
        },
      ],
    },
    {
      from: 'teacher',
      message:
        'Este año vamos a tener una ayuda muy especial que nos ayudará todo el curso, ¿queréis saber quién es? Os resolverá dudas, os dará pistas, hará resúmenes y os avisará de los deberes que os faltan.',
      responses: [
        {
          from: 'student01@oneclick.es',
          message: '¡Yo sé quién es! ¡Se llama YoY! ¡Quiero un peluche de YoY!',
        },
        {
          from: 'student03@oneclick.es',
          message: '¡Está por todos lados ayudándonos!',
        },
      ],
    },
    {
      from: 'student02@oneclick.es',
      message: 'Buenos días, profe, he visto en el libro algo que se llama Kanban, ¿qué es eso?',
      responses: [
        {
          from: 'teacher',
          message:
            'Hola, muy buena pregunta. Un Kanban es una herramienta visual para organizar las tareas del curso. Cada tarea se coloca en una columna según el estado en el que se encuentran: tareas que están por hacer, tareas pendientes y tareas terminadas. De un vistazo sabrás cómo llevas tus proyectos… ',
        },
      ],
    },
    {
      from: 'teacher',
      message:
        'Os propongo un ejercicio aprovechando este muro. Voy a poner un refrán todas las semanas y tendréis que decirme lo que significa. El primero que responda bien, se llevará un punto, vamos a hacer un concurso este año. El primero es: “El que lee mucho y anda mucho, ve mucho y sabe mucho”',
      responses: [
        {
          from: 'student02@oneclick.es',
          message: 'Eso es que tenemos que leer y hacer deporte para estar sanos!',
        },
        {
          from: 'student01@oneclick.es',
          message: '¡No, quiere decir que cuanto más estudiemos y viajemos, más listos seremos!',
        },
      ],
    },
  ],
};

export const defaultTimes = [
  {
    id: 'quarter1',
    days: [
      {
        guid: new Date().getTime(),
        value: ['08:00', '09:00'],
        day: 'monday',
      },
    ],
  },
  {
    id: 'quarter2',
    days: [
      {
        guid: new Date().getTime(),
        value: ['08:00', '09:00'],
        day: 'monday',
      },
    ],
  },
  {
    id: 'quarter3',
    days: [
      {
        guid: new Date().getTime(),
        value: ['08:00', '09:00'],
        day: 'monday',
      },
    ],
  },
];

export const TEMPLATES = {
  VANILLA: 'vanilla',
  RETRO: 'retro',
  PRIMARY: 'primary-2022',
  SECONDARY: 'secondary-2022',
  PQLCO: 'secondary-pqlco',
  LITE: 'lite',
  PRIMARY_2025: 'primary-2025',
};

export const KANBAN_TEMPLATE_GUID = 'kanban-4-columns';

export const CONFIG = {
  googleKeys: {
    CLIENT_ID: '733400687741-2r5msegkj6s1rd5ku03hjlrgpoelgptl.apps.googleusercontent.com',
    DEVELOPER_KEY: process.env.REACT_APP_GOOGLE_DEVELOPER_KEY,
    APP_ID: 'tangerine-edelvivesdigital-dev',
    SCOPE: ['https://www.googleapis.com/auth/drive.readonly'],
  },
  isBooksGradebookAllowed: true,
  isTreasureHuntAllowed: true,
  gradebookScopes: [
    {
      label: 'course:Program',
      value: 'program',
    },
    {
      label: 'my-courses:Resources',
      value: 'toolbox',
    },
  ],
};

export const TERMS = {
  vl: `<div id="tyc">
  <div class="tyc_legal">
  <div class="terms">
  <p>En compliment de l'article 10 de la Llei 34/2002, d'11 de juliol, de Serveis de la Societat de la Informació i Comerç Electrònic, s'exposen a continuació les dades identificatives del titular del lloc web <a target="_blank" href="https://edelvivesdigitalplus.com/">https://edelvivesdigitalplus.com/</a> (d'ara en avant, el <strong>«Lloc web»</strong>): </p>
<p><strong>TITULAR</strong>: Grupo Editorial Luis Vives (d'ara en avant, «GRUPO EDELVIVES»)</p>
<p><strong>CIF</strong>: R 5000274 J</p>
<p><strong>Domicili</strong>: Ctra. de Madrid km. 315,700; C.P. 50.012 Saragossa (Espanya)</p>
<p>Entitat pertanyent a l'Institut de Germans Maristes de l'Ensenyament; inscrita en el Registre d'Entitats Religioses del Ministeri de Justícia, amb el número 1334-i/28-ES/B.</p>
<p>L'accés, navegació i utilització del Lloc web implica l'acceptació expressa i sense reserves de tots els termes de les presents condicions d'ús, tenint la mateixa validesa i eficàcia que qualsevol contracte subscrit per escrit i signat.</p>
<p>La seua observança i compliment serà exigible respecte de qualsevol persona que accedisca, navegue o utilitze la Web. Si no està d'acord amb els termes exposats, no accedisca, navegue o utilitze cap pàgina del Lloc web.</p>
<h3>CONDICIONS GENERALS D'ÚS</h3>
<p>Les presents condicions d'ús regulen l'accés, navegació i utilització del Lloc web. <strong>GRUPO EDELVIVES</strong> es reserva el dret a modificar la presentació, configuració i contingut del Lloc Web, així com les condicions requerides per a accedir-hi o utilitzar-lo. L'accés i utilització dels continguts del Lloc web després de l'entrada en vigor de les modificacions o canvis suposen l'acceptació d'aquests.</p>
<p>No obstant això, l'accés a determinats continguts i la utilització de determinats serveis pot sotmetre’s a determinades condicions particulars, que seran, en tot cas, clarament mostrades i hauran de ser acceptades expressament pels usuaris. Aquestes condicions particulars podran substituir, completar o, si escau, modificar les presents condicions d'ús.</p>
<p><strong>GRUPO EDELVIVES</strong> es reserva el dret a modificar els termes i condicions ací estipulades, totalment o parcialment, publicant qualsevol canvi en la mateixa forma en què apareixen aquestes condicions d'ús o a través de qualsevol mena de comunicació dirigida als usuaris.</p>
<h3>ACCÉS I SEGURETAT</h3>
<p>Amb caràcter general, els Usuaris podran accedir al Lloc web de manera lliure i gratuïta. No obstant això, Grupo Editorial Luis Vives es reserva, conforme a la legislació vigent, el dret de limitar l'accés a determinades àrees del Lloc web per a les quals l'Usuari haurà de registrar-se i facilitar tota la informació sol·licitada, de forma actualitzada i real (vegeu en cada cas la política de privacitat).</p>
<p>
Queda prohibit l'accés al Lloc web per part de menors d'edat.
</p>
<p>En cap concepte <strong>GRUPO EDELVIVES</strong> es responsabilitzarà de la veracitat de les dades de registre facilitades pels usuaris finals, que seran responsables de les possibles conseqüències, errors i fallades que posteriorment puguen derivar-se de la falta de veracitat de les dades.</p>
<h3>DRETS DE PROPIETAT INTEL·LECTUAL I INDUSTRIAL</h3>
<p><strong>GRUPO EDELVIVES</strong> és titular o, si escau, compta amb les llicències corresponents sobre els drets d'explotació de propietat intel·lectual i industrial del Lloc web, així com dels drets de propietat intel·lectual i industrial sobre la informació, materials i continguts d'aquest.
</p>
<p>En cap cas s'entendrà que l'accés, navegació i utilització del Lloc web per part de l'usuari implica una renúncia, transmissió, llicència o cessió total o parcial d'aquests drets per part de GRUPO EDELVIVES. L'usuari disposa d'un dret d'ús dels continguts o serveis del Lloc web dins d'un àmbit estrictament domèstic.</p>
<p>Les referències a marques o noms comercials registrats, o altres signes distintius, siguen titularitat de <strong>GRUPO EDELVIVES</strong> siguen de terceres empreses, porten implícites la prohibició d’usar-les sense el consentiment de <strong>GRUPO EDELVIVES</strong> o dels seus legítims titulars. En cap moment, excepte manifestació expressa en contra, l'accés, navegació o utilització del Lloc web i/o dels seus continguts, confereix a l'usuari cap dret sobre els signes distintius que s’hi inclouen.</p>
<p>Queden reservats tots els drets de propietat intel·lectual i industrial sobre els continguts i/o serveis del Lloc web i, en particular, queda prohibit modificar, copiar, reproduir, comunicar públicament, transformar o distribuir, per qualsevol mitjà i en qualsevol forma, la totalitat o part dels continguts inclosos en la Web, per a propòsits públics o comercials, si no es compta amb l'autorització prèvia, expressa i per escrit de <strong>GRUPO EDELVIVES</strong> o, si escau, del titular dels drets corresponents.</p>
<p>En el cas que l'usuari envie informació de qualsevol tipus a <strong>GRUPO EDELVIVES</strong> a través qualsevol dels canals habilitats a aquest efecte, l'usuari declara, garanteix i accepta que té dret a fer-ho lliurement, que aquesta informació no infringeix cap dret de propietat intel·lectual, industrial, secret comercial o qualssevol altres drets de tercers, i que aquesta informació no té caràcter confidencial ni és perjudicial per a tercers.</p>
<p>L'usuari reconeix assumir la responsabilitat i deixa indemne <strong>GRUPO EDELVIVES</strong> per qualsevol comunicació que subministre personalment o al seu nom; eixa responsabilitat inclou, sense cap restricció, l'exactitud, legalitat, originalitat i titularitat d'aquesta.</p>
<h3>MARQUES</h3>
<p>Les marques, logotips i qualssevol signes de propietat industrial que apareixen en aquest Lloc són propietat de <strong>GRUPO EDELVIVES</strong> o de terceres parts. Està prohibida la utilització d'aquestes marques sense la prèvia autorització escrita de GRUPO EDELVIVES, o de les terceres parts propietàries de les marques.</p>
<h3>ENLLAÇOS</h3>
<h4>ENLLAÇOS A ALTRES PÀGINES WEB</h4>
<p>En cas que en el Lloc web es mostraren enllaços a altres pàgines web a través de botons, enllaços, bàners o continguts incrustats, <strong>GRUPO EDELVIVES</strong> informa que aquests es troben directament gestionats per tercers, i que <strong>GRUPO EDELVIVES</strong> no té ni mitjans humans, ni tècnics per a conéixer de manera prèvia, controlar i/o aprovar tota la informació, continguts, productes o serveis facilitats per altres llocs web als quals es puguen establir enllaços des del present Lloc web.</p>
<p>En conseqüència, <strong>GRUPO EDELVIVES</strong> no podrà assumir cap mena de responsabilitat per qualsevol aspecte relatiu a la pàgina web a la qual es poguera establir un enllaç des del Lloc web, en concret, a títol enunciatiu i no limitatiu, sobre el seu funcionament, accés, dades, informació, arxius, qualitat i fiabilitat dels seus productes i serveis, els seus propis enllaços i/o qualsevol dels seus continguts, en general.</p>
<p>En aquest sentit, si els Usuaris tingueren coneixement efectiu que les activitats desenvolupades a través d'aquestes pàgines web de tercers són il·legals o contravenen la moral i/o l'ordre públic, hauran de comunicar-lo immediatament a <strong>GRUPO EDELVIVES</strong> perquè es procedisca a deshabilitar l'enllaç d'accés a aquestes, acció que es durà a terme en el menor temps possible.</p>
<p>En qualsevol cas, l'establiment de qualsevol classe d'enllaç des del Lloc web a una altra pàgina web aliena no implicarà que existisca algun tipus de relació, col·laboració o dependència entre <strong>GRUPO EDELVIVES</strong> i el responsable de la pàgina web aliena.</p>
<h3>ENLLAÇOS DE TERCERS</h3>
<p><strong>GRUPO EDELVIVES</strong> posa a la disposició dels usuaris, a través de diverses eines i aplicacions, mitjans d'enllaç que permeten als usuaris accedir a canals i pàgines en diferents webs. La instal·lació d'aquestes aplicacions en el Lloc web té per únic objecte facilitar als usuaris l'accés a aquests canals en les diferents plataformes i xarxes socials.
L'establiment d'aquestes aplicacions no implica l'existència de cap relació entre <strong>GRUPO EDELVIVES</strong> i el propietari, fabricador o distribuïdor de la plataforma enllaçada, com tampoc l'acceptació i aprovació per part de <strong>GRUPO EDELVIVES</strong> dels seus continguts i/o serveis; és el seu propietari, fabricador o distribuïdor l'únic responsable d'aquests.</p>
<p>En cap cas, <strong>GRUPO EDELVIVES</strong> comparteix amb els propietaris de tals pàgines web externes, cap mena d'informació privada sobre els seus usuaris. La seua única finalitat és facilitar l'accés a elles per part dels usuaris. En aquest sentit, tota la informació que l'usuari desitge proporcionar a aquestes plataformes i/o pàgines web externes, serà sota la seua pròpia responsabilitat; <strong>GRUPO EDELVIVES</strong> no intervendrà en aquest procés.</strong>.
</p>
<p>Pel fet que <strong>GRUPO EDELVIVES</strong> no té cap control sobre el contingut allotjat en aquests canals, l'usuari reconeix i accepta que <strong>GRUPO EDELVIVES</strong> no assumeix cap responsabilitat pel contingut ni pels serveis als quals l'usuari puga accedir en aquestes pàgines ni per qualsevol contingut, producte, servei, publicitat, ni qualsevol altre material disponible en aquests. Per tal motiu, l'usuari ha d'extremar la prudència en la valoració i utilització de la informació, continguts i serveis existents en els canals enllaçats, i sobre la informació pròpia o de tercers que vulga compartir en aquests canals.</p>
<h3>ENLLAÇOS EN ALTRES PÀGINES WEB AMB DESTINACIÓ AL LLOC WEB</h3>
<p><strong>GRUPO EDELVIVES</strong> no autoritza l'establiment d'un enllaç al present Lloc web des d'altres pàgines web que continguen materials, informació o continguts il·lícits, il·legals, degradants, obscens i, en general, que contravinguen les lleis, la moral o l'ordre públic, o les normes socials generalment acceptades.
En tot cas, els usuaris podran establir enllaços en les seues respectives pàgines web sempre que sol·liciten l'autorització prèvia i expressa a <strong>GRUPO EDELVIVES</strong>.</p>
<p><strong>GRUPO EDELVIVES</strong> no té facultat ni mitjans humans i tècnics per a conéixer, controlar ni aprovar tota la informació, continguts, productes o serveis facilitats per altres llocs web que tinguen establits enllaços amb destinació al present Lloc web. <strong>GRUPO EDELVIVES</strong> no assumeix cap mena de responsabilitat per qualsevol aspecte relatiu a les pàgines web que estableixen un enllaç al present Lloc web, en concret, a títol enunciatiu i no taxatiu, sobre el seu funcionament, accés, dades, informació, arxius, qualitat i fiabilitat dels seus productes i serveis, els seus propis enllaços i/o qualsevol dels seus continguts, en general.</p>
<h3>CONDICIONS D'ÚS DEL LLOC WEB</h3>
<p>No està permés i, per tant, les seues conseqüències seran de l'exclusiva responsabilitat de l'usuari, l'accés o la utilització del Lloc web amb finalitats il·legals o no autoritzades, amb objectiu econòmic o sense, i, més específicament i sense que el següent llistat tinga caràcter absolut, queda prohibit:</p>
<ol>
  <li>
    <p>Usar el Lloc web en cap forma que puga provocar danys, interrupcions, ineficiències o defectes en la seua funcionalitat o en l'ordinador d'un tercer;</p>
  </li>
  <li>
    <p>Usar el Lloc web per a transmetre, instal·lar o publicar qualsevol virus, codi maliciós o altres programes o arxius perjudicials;</p>
  </li>
  <li>
    <p>Usar el Lloc web per a recollir dades de caràcter personal d'altres usuaris;</p>
  </li>
  <li>
    <p>Registrar-se a través del Lloc web amb una identitat falsa, suplantar tercers o utilitzar un perfil o fer qualsevol altra acció que puga confondre altres usuaris sobre la identitat de l'origen d'un missatge;</p>
  </li>
  <li>
    <p> Accedir sense autorització a qualsevol secció del Lloc web, a altres sistemes o xarxes connectades a la Plataforma, a qualsevol servidor de <strong>GRUPO EDELVIVES</strong> o als serveis oferits a través del Lloc web, per mitjà de pirateig o falsificació, extracció de contrasenyes o qualsevol altre mitjà il·legítim;</p>
  </li>
  <li>
    <p>Infringir, o intentar infringir, les mesures de seguretat o autenticació del Lloc web o de qualsevol xarxa connectada a aquest, o les mesures de seguretat o protecció inherents als continguts oferits en el Lloc web;</p>
  </li>
  <li>
    <p>Dur a terme alguna acció que provoque una saturació desproporcionada o innecessària en la infraestructura del Lloc web o en els sistemes o xarxes de GRUPO EDELVIVES, així com en els sistemes i xarxes connectats al Lloc web;;</p>
  </li>
  <li>
    <p> Impedir el desenvolupament normal d'un esdeveniment, concurs, promoció o qualsevol altra activitat disponible a través del Lloc web o qualssevol de les seues funcionalitats, en alterar o en tractar d'alterar, il·legalment o de qualsevol altra forma, l'accés, participació o funcionament d'aquells, en falsejar el resultat dels mateixos o en utilitzar mètodes de participació fraudulents, mitjançant qualsevol procediment o a través de qualsevol pràctica que atempte o vulnere d’alguna manera les presents Condicions d'ús.</p>
  </li>
</ol>
<p>L'incompliment de qualsevol de les anteriors obligacions per part de l'usuari, podrà portar a <strong>GRUPO EDELVIVES</strong> a adoptar les mesures oportunes emparades en Dret i en l'exercici dels seus drets o obligacions, incloent-hi l'eliminació o bloqueig del compte de l'usuari infractor, sense que medie possibilitat de cap indemnització pels danys i perjudicis causats.</p>
<h3>RESPONSABILITATS I GARANTIES </h3>
<p><strong>GRUPO EDELVIVES</strong> no pot garantir la fiabilitat, utilitat o veracitat d'absolutament tota la informació i/o dels serveis del Lloc web, ni tampoc de la utilitat o veracitat de la documentació posada a disposició a través d'aquesta.</p>
<p> En conseqüència, <strong>GRUPO EDELVIVES</strong> no garanteix ni es fa responsable de: (i) la continuïtat dels continguts del Lloc web; (ii) l'absència d'errors en aquests continguts; (iii) l'absència de virus i/o altres components nocius en el Lloc web o en el servidor que el subministra; (iv) la invulnerabilitat del Lloc web i/o la impossibilitat de vulnerar les mesures de seguretat que s'adopten en aquesta; (v) la falta d'utilitat o rendiment dels continguts del Lloc web; i (vi) els danys o perjudicis que cause, a si mateix o a un tercer, qualsevol persona que infringira les condicions, normes i instruccions que <strong>GRUPO EDELVIVES</strong> estableix en el Lloc web o a través de la vulneració dels sistemes de seguretat del Lloc web. No obstant això, <strong>GRUPO EDELVIVES</strong> declara que ha adoptat totes les mesures necessàries, dins de les seues possibilitats i de l'estat de la tècnica, per a garantir el funcionament del Lloc web i reduir al mínim els errors del sistema, tant des del punt de vista tècnic, com dels continguts publicats en el Lloc web. Si l'usuari tinguera coneixement de l'existència d'algun contingut il·lícit, il·legal, contrari a les lleis o que poguera suposar una infracció de drets de propietat intel·lectual o industrial, de la normativa aplicable en material de protecció de dades personals o qualsevol altre dret, haurà de notificar-lo immediatament a <strong>GRUPO EDELVIVES</strong> perquè puga procedir a l'adopció de les mesures oportunes. <strong>GRUPO EDELVIVES</strong> no garanteix la licitud, fiabilitat i utilitat dels continguts subministrats per tercers a través del Lloc web. </p>
<p><strong>GRUPO EDELVIVES</strong> no serà responsable sobre la veracitat, integritat o actualització de les informacions publicades en el Lloc web provinents de fonts alienes al mateix (notícies externes, informes de professionals externs, etc.), així com tampoc de les informacions contingudes en altres portals web enllaçats des del Lloc web. <strong>GRUPO EDELVIVES</strong> no assumirà responsabilitat sobre hipotètics perjudicis que pogueren originar-se per l'ús de les citades informacions. En tot cas, <strong>GRUPO EDELVIVES</strong> es reserva el dret a suspendre, modificar, restringir o interrompre, temporalment o permanentment, l'accés, navegació, ús, allotjament i descàrrega del contingut o ús de serveis del Lloc web, amb prèvia notificació o sense, als usuaris que contravinguen qualsevol de les disposicions detallades en les presents condicions d'ús, sense que l’usuari tinga possibilitat d'exigir cap indemnització per aquesta causa. </p>
</div>

<div class="pprivacy">
De conformitat amb el que es disposa per la legislació vigent i aplicable en matèria de protecció de dades de caràcter personal, totes les dades de caràcter personal facilitades durant la utilització del Lloc web seran tractades de conformitat amb el que es disposa en la Política de Privacitat que tot usuari ha d'acceptar expressament per a poder utilitzar i registrar-se en el Lloc web. Tot usuari que accepte les presents Condicions d'ús, accepta de manera informada, expressa i inequívoca la nostra Política de Privacitat, podent exercir els drets que en aquesta matèria li corresponen, segons informa l'esmentada Política de Privacitat.
</p>

  <h3>LLEI APLICABLE I JURISDICCIÓ COMPETENT</h3>
  <p>Aquestes Condicions d'ús es regeixen per la llei espanyola. Les parts se sotmeten expressament, per a la resolució dels conflictes i amb renúncia a qualsevol altre fur, als jutjats i tribunals de Madrid capital. Per a presentar reclamacions en l'ús dels nostres serveis, el client pot dirigir-se per correu a l'adreça electrònica o física indicada en l'apartat «Identificació». Ens comprometem a buscar en tot moment una solució amistosa del conflicte.</p>
  <p>Última actualització: 21 de gener de 2019 </p>
  <p>© 2019 <strong>GRUPO EDELVIVES</strong> Tots els drets reservats </p>
  <h3 id="privacy-policy"><a name="punto3a"></a>POLÍTICA DE PRIVACITAT </h3>
  <ol>
    <li>
      <h3>IDENTIFICACIÓN</h3>
      <ul>
        <li>Identidad: <strong>GRUPO EDITORIAL LUIS VIVES</strong> (d'ara en avant, «GRUPO EDELVIVES»)</li>
        <li>Domicilio: Carretera de Madrid, Km 315,700, 50001-Saragossa</li>
        <li>NIF: R5000274J</li>
        <li>Delegat de Protecció de Dades (DPO): pot contactar amb el nostre DPO a través dels següents mitjans:
          - E-mail: dpo@edelvives.es
          - Dirección: Carrer Xaudaró, 25, 28034 Madrid.  </li>
      </ul>Indique en la referència «Delegat de Protecció de Dades».
    </li>
    <li>
      <h3>INFORMACIÓ I CONSENTIMENT.</h3>
      <p>Mitjançant l'acceptació de la present Política de Privacitat, l'usuari queda informat i presta el seu consentiment lliure, informat, específic i inequívoc perquè les dades personals que facilite a través de la pàgina web situada en l'URL https://edelvivesdigitalplus.com/ (d'ara en avant, el «Lloc web») siguen trarctades per GRUPO EDELVIVES, així com les dades derivades de la navegació i aquelles altres dades que puga facilitar en un futur a <strong>GRUPO EDELVIVES</strong>.</p>
      <p>L'usuari ha de llegir amb atenció aquesta Política de Privacitat, que s’ha redactat de manera clara i senzilla, per a facilitar-ne la comprensió, i ha de determinar lliurement i voluntàriament si desitja facilitar les seues dades personals a GRUPO EDELVIVES. Els MENORS D'EDAT, només poden aportar dades personals amb el previ consentiment de pares o tutors que s’ha d’enviar, degudament signat i amb còpia del DNI del pare, mare o tutor signant, a l'adreça rgpd@edelvives.es <strong>GRUPO EDELVIVES</strong> no respon de les dades de menors que, sense poder conéixer aquest fet, s'hagen facilitat sense consentiment de pare, mare o tutor.</p>
    </li>
    <li>
      <h3>OBLIGATORIETAT DE FACILITAR LES DADES. </h3>
      <p>Les dades sol·licitades en els formularis del Lloc web són, amb caràcter general, obligatòries (llevat que en el camp requerit s'especifique el contrari) per a complir amb les finalitats establides. Aquestes finalitats no podran atendre’s si no es faciliten les dades o no es faciliten, sense perjudici que l’usuari puga visualitzar lliurement el contingut del Lloc web. </p>
    </li>
    <li>
      <h3>AMB QUINA FINALITAT TRACTARÀ <strong>GRUPO EDELVIVES</strong> LES DADES PERSONALS DE L'USUARI? </h3>
      <p>Les dades personals facilitades a través del Lloc web seran tractades per <strong>GRUPO EDELVIVES</strong> conforme a les següents finalitats:</p>
      <ol>
        <li>Dades facilitades per al registre en el Portal d'usuari</strong>:
          <ul>
            <li>
              <p>Gestionar el registre com a usuari en la plataforma en línia propietat de <strong>GRUPO EDELVIVES</strong> i allotjada en el present Lloc web. </p>
            </li>
            <li>
              <p>Gestionar el contingut del Portal.</p>
            </li>
            <li>
              <p>Efectuar anàlisis sobre la utilització del Lloc web i comprovar les preferències i comportament dels usuaris.</p>
            </li>
          </ul>
        </li>
        <li>
          <p>Dades facilitades en els formularis de contacte i altres canals posats a la disposició dels usuaris:</p>
          <ul>
            <li>
              <p>Gestionar les sol·licituds de contacte i informació de l'usuari a través dels canals disposats per a això en els llocs web de GRUPO EDELVIVES.</p>
            </li>
            <li>
              <p>Gestió de la petició plantejada.</p>
            </li>
            <li>
              <p>Efectuar anàlisis sobre la utilització del Lloc web i comprovar les preferències i comportament dels usuaris. </p>
            </li>
          </ul>
        </li>
      </ol>
    </li>
    <li>
      <h3>QUINES DADES DE L'USUARI TRACTARÀ GRUPO EDELVIVES? </h3>
      <p><strong>GRUPO EDELVIVES</strong> tractarà les següents categories de dades de l'usuari:</p>
      <ol>
        <li>
          <p>	Dades facilitades per al registre en el Portal d'usuari </p>
          <ul>
            <li>
              <p>Dades identificatives: nom, cognoms.</p>
            </li>
            <li>
              <p>Dades de contacte: domicili, adreça de correu electrònic, número de telèfon.</p>
            </li>
            <li>
              <p>Altres dades: dades facilitades pels interessats en els camps oberts.</p>
            </li>
            <li>
              <p>Dades de navegació.</p>
            </li>
          </ul>
        </li>
        <li>
          <p>Dades facilitades en els formularis de contacte i altres canals posats a la disposició dels usuaris:</p>
          <ul>
            <li>
              <p>Dades identificatives: nom, cognoms.</p>
            </li>
            <li>
              <p>Dades de contacte: adreça de correu electrònic, número de telèfon.</p>
            </li>
            <li>
              <p>Dades de transacció de béns i serveis.</p>
            </li>
            <li>
              <p>Altres dades: dades facilitades pels interessats en els camps oberts dels formularis disposats en el Lloc web o en els documents adjunts.
             </p>
            </li>
            <li>
              <p>	Dades de navegació.</p>
            </li>
          </ul>
          <p>En cas que l'usuari facilite dades de tercers, manifesta comptar amb el consentiment dels mateixos i es compromet a traslladar-li la informació continguda en la Política de Privacitat. Eximeix així <strong>GRUPO EDELVIVES</strong> de qualsevol responsabilitat en aquest sentit. No obstant això, <strong>GRUPO EDELVIVES</strong> podrà dur a terme les verificacions periòdiques per a constatar aquest fet i adoptar les mesures de diligència deguda que correspongue conforme a la normativa de protecció de dades.</p>
        </li>
      </ol>
    </li>
    <li>
      <h3>QUINA ÉS LA LEGITIMACIÓ DEL TRACTAMENT DE LES DADES DE L'USUARI?</h3>
      <p>La legitimació del tractament de les seues dades personals, seran les següents:</p>
      <ol>
        <li>
          <p>Dades facilitades per al registre en el Portal d'usuari: el consentiment que se li sol·licita i que pot retirar en qualsevol moment. No obstant això, en cas de retirar el seu consentiment, això no afectarà la licitud dels tractaments efectuats amb anterioritat. Per a l'execució d'anàlisis sobre la utilització de la web, l'interés legítim.</p>
        </li>
        <li>
          <p>2.Per als formularis de contacte i altres canals posats a la disposició dels usuaris: el consentiment que se li sol·licita i que pot retirar en qualsevol moment. No obstant això, en cas de retirar el seu consentiment, això no afectarà la licitud dels tractaments efectuats amb anterioritat. Per a l'execució d'anàlisis sobre la utilització de la web, l'interés legítim de GRUPO EDELVIVES.</p>
        </li>
      </ol>
      <p>Els consentiments obtinguts per a les finalitats esmentades són independents; l'usuari podrà revocar només un d'ells sense afectar els altres.</p>
      <p>Per a revocar aquest consentiment, l'Usuari podrà contactar amb GRUPO EDELVIVES, en qualsevol moment, a través dels canals següents: <a target="_blank" href="mailto:rgpd@edelvives.es">rgpd@edelvives.es</a>,
      indicant en l'assumpte de referència «Dades Personals».</p>
    </li>
    <li>
      <h3>AMB QUINS DESTINATARIS ES COMPARTIRAN LES DADES DE L'USUARI?</h3>
      <p>Les dades de l'usuari podran ser comunicar-se a:</p>
      <ol>
        <li>
          <p>1. Dades facilitades per al registre en el Portal d'usuari: Empreses del grup al qual pertany GRUPO EDELVIVES, únicament per a fins administratius interns i/o per a les finalitats anteriorment indicades.</p>
        </li>
        <li>
          <p>2. Dades facilitades per als formularis de contacte i altres canals posats a la disposició dels usuaris: Empreses del grup al qual pertany GRUPO EDELVIVES, únicament per a fins administratius interns i/o per a les finalitats anteriorment indicades.</p>
        </li>
      </ol>
      <p>Addicionalment, les dades podran ser accessibles per proveïdors de GRUPO EDELVIVES. Aquest accés serà el necessari per a l'adequat compliment de les obligacions legals i/o de les finalitats anteriorment indicades. Aquests proveïdors no tractaran les seues dades per a finalitats pròpies que no hagen sigut prèviament informades per <strong>GRUPO EDELVIVES</strong>.</p>
    </li>
    <li>
      <h3>CONSERVACIÓ DE LES DADES</h3>
      <p>Les seues dades seran conservades durant els terminis següents:</p>
      <ol>
        <li>
          <p>Dades facilitades per al registre en el Portal d'usuari: seran conservades fins que l'usuari sol·licite la baixa i, una vegada sol·licitada, durant el termini de prescripció d'accions legals derivades de l'ús d'aquesta.</p>
        </li>
        <li>
          <p>2. Dades facilitades per als formularis de contacte i altres canals posats a la disposició dels usuaris: seran conservades durant el termini necessari per a donar tràmit i contestació a la sol·licitud i, una vegada finalitzat, durant el termini de prescripció de les accions legals derivades de la citada sol·licitud.</p>
        </li>
      </ol>
    </li>
    <li>
      <h3>RESPONSABILITAT DE L'USUARI.</h3>
      <p>L'usuari:</p>
      <ul>
        <li>
          <p>Garanteix que és major de díhuit (18) anys i que les dades que facilita a <strong>GRUPO EDELVIVES</strong> són vertaderes, exactes, completes i actualitzades. A aquest efecte, l'usuari respon de la veracitat de totes les dades que comunique i mantindrà convenientment actualitzada la informació facilitada, de tal forma que responga a la seua situació real.</p>
        </li>
        <li>
          <p>	Garanteix que ha informat els tercers dels quals en facilite les dades, en cas de fer-ho, dels aspectes continguts en aquest document. Així mateix, garanteix que ha obtingut l’autorització per a facilitar aquestese dades a <strong>GRUPO EDELVIVES</strong> per als fins assenyalats.</p>
        </li>
        <li>
          <p>Serà responsable de les informacions falses o inexactes que proporcione a través del Lloc web i dels danys i perjudicis, directes o indirectes, que això cause a <strong>GRUPO EDELVIVES</strong> o a tercers.</p>
        </li>
      </ul>
    </li>
    <li>
      <h3>EXERCICI DE DRETS.</h3>
      <p>L'usuari pot enviar un escrit a GRUPO EDELVIVES, a l'adreça indicada en l'encapçalament de la present Política, o bé per mitjà d'un correu electrònic a l'adreça rgpd@edelvives.és, adjuntant fotocòpia del document d'identitat, en qualsevol moment i de manera gratuïta, per a:</p>
      <ul>
        <li>
          <p>	Revocar els consentiments atorgats.</p>
        </li>
        <li>
          <p>Obtindre confirmació sobre si en <strong>GRUPO EDELVIVES</strong> s'estan tractant dades personals que concerneixen l'usuari o no.</p>
        </li>
        <li>
          <p>Accedir a les seues dades personals.</p>
        </li>
        <li>
          <p>Rectificar les dades inexactes o incompletes.</p>
        </li>
        <li>
          <p>Sol·licitar la supressió de les seues dades quan, entre altres motius, les dades ja no siguen necessàries per als fins pels quals van ser recollides.</p>
        </li>
        <li>
          <p>Obtindre de <strong>GRUPO EDELVIVES</strong> la limitació del tractament de les dades quan es complisca alguna de les condicions previstes en la normativa de protecció de dades.</p>
        </li>
        <li>
          <p>Sol·licitar la portabilitat de les dades facilitades per l'Usuari en aquells casos previstos en la normativa</p>
        </li>
        <li>
          <p>Posar-se en contacte amb el DPO de <strong>GRUPO EDELVIVES</strong> a través de la següent adreça: <a target="_blank" href="mailto:dpo@edelvives.es">dpo@edelvives.es</a></p>
        </li>
        <li>
          <p>Interposar una reclamació relativa a la protecció de les seues dades personals davant l'Agència Espanyola de Protecció de Dades en l'adreça Carrer de Jorge Juan, 6, 28001 Madrid, quan l'interessat considere que <strong>GRUPO EDELVIVES</strong> ha vulnerat els drets que li són reconeguts per la normativa aplicable en protecció de dades.</p>
        </li>
      </ul>
    </li>
    <li>
      <h3>MESURES DE SEGURETAT.</h3>
      <p><strong>GRUPO EDELVIVES</strong> tractarà les dades de l'usuari, en tot moment, de forma absolutament confidencial i guardant el preceptiu deure de secret respecte d'aquestes, de conformitat amb el que es preveu en la normativa d'aplicació. Adoptarà a aquest efecte les mesures d'índole tècnica i organitzativa necessàries per a garantir la seguretat de les seues dades i evitar-ne l’alteració, pèrdua, tractament o accés no autoritzat, tenint en compte l'estat de la tecnologia, la naturalesa de les dades emmagatzemades i dels riscos als quals s’exposen. 
      Última actualització: 21 de gener de 2019. </p>
    </li>
  </ol>
</div>
<div class="ccokies">
  <h3><a name="punto3a"></a>POLÍTICA DE COOKIES</h3>
  <p>Aquesta Política de Cookies és part integrant de l'Avís Legal i la Política de Privacitat de la pàgina web https://edelvivesdigitalplus.com/ (d'ara en avant, el «Lloc web»). L'accés i la navegació en el Lloc web, o l'ús dels serveis d'aquest, impliquen l'acceptació dels termes i condicions recollits en l'Avís Legal i en la Política de Privacitat. 
  Amb l’objectiu de facilitar la navegació pel Lloc web, GRUPO  EDITORIAL LUIS VIVES, com a titular del mateix (d'ara en avant, «GRUPO EDELVIVES») amb domicili social en Ctra. de Madrid km. 315,700, 50.012, Saragossa, amb NIF R 5000274 J, li comunica que utilitza cookies o altres dispositius d'emmagatzematge i recuperació de dades de funcionalitat similar (d'ara en avant, les «cookies»). En aquest sentit, i amb l'objectiu de garantir a l'usuari tota la informació necessària per a la correcta navegació, posem a la disposició de l'usuari el següent text informatiu sobre què són les cookies, quina tipologia de cookiies existeixen en la nostra Pàgina Web i com és possible configurar-les o deshabilitar-les. </p>
  <ol>
    <li>
      <p>Què és una cookie?</p>
      <p>Les cookies són arxius que contenen xicotetes quantitats d'informació que es descarreguen en el dispositiu de l'usuari quan visita una pàgina web. La seua finalitat principal és reconéixer l'usuari cada vegada que accedeix al Lloc web per a millorar la qualitat i oferir un millor ús del Lloc web. Les cookies són essencials per al funcionament d'internet, ja que ajuden, entre altres funcions, a identificar i resoldre possibles errors de funcionament del Lloc web. </p>
    </li>
    <li>
      <p>Tipologia de cookies</p>
      <p>En el present Lloc web s'utilitzen cookies pròpies i de tercers. Les cookies utilitzades s'emmagatzemen en el seu ordinador durant un temps determinat, aquestes poden considerar-se cookies de sessió (si caduquen quan abandona el nostre Lloc web) o persistents (si la caducitat de les cookies dura més temps). Les cookies utilitzades en la present pàgina Web són: </p>
      <p><strong>Cookies «estrictament necessàries» </strong> 
      
      <p>Són aquelles cookies considerades imprescindibles per a la navegació per la Pàgina Web, perquè faciliten a l'usuari la utilització de les seues prestacions o eines com, per exemple, identificar la sessió, accedir a parts d'accés restringit, recordar els elements que integren una comanda, realitzar el procés de compra d'una comanda, entre d’altres. Sense aquestes cookies, les funcions esmentades quedarien inhabilitades.</p>

      <p><strong>Cookies sobre comportament </strong>
        </p><p>Són aquelles cookies l'objectiu de les quals és recopilar informació relativa als usos de navegació de l'usuari, com per exemple les pàgines que són més visitades o els missatges d'error, entre d’altres, amb l'objectiu per part del responsable, d'introduir millores en la pàgina web a partir de la informació recopilada per aquestes cookies.</p>
        <p>La informació recopilada per aquestes cookies és anònima, no permet identificar l'usuari personalment i, per tant, s'utilitza exclusivament per al correcte funcionament de la pàgina web. </p>
      <p></p>
      <p><strong>Cookies de funcionalitat </strong>
        </p><p>Són aquelles cookies que permeten recordar a la pàgina web les decisions que l'usuari pren, per exemple: s'emmagatzemarà la ubicació geogràfica de l'usuari per a assegurar que es mostra el lloc web indicat per a la seua regió, el nom d'usuari, l'idioma o el tipus de navegador mitjançant el qual s'accedeix a la pàgina web, entre d’altres. L'objectiu és la prestació d'un servei més personalitzat. La informació recopilada per aquestes cookies és anònima, no permet identificar l'usuari personalment i, per tant, s'utilitza exclusivament per al funcionament de la pàgina web. </p>
       
      <p></p>
      <p><strong>Cookies de personalització i de publicitat </strong>
        </p><p>Són aquelles cookies que utilitzen els operadors publicitaris amb el permís del titular de la pàgina web. Recullen informació sobre les preferències i eleccions de l'usuari en la seua navegació per llocs web i es dirigeixen a les xarxes de publicitat, que les utilitzen per a després mostrar a l'usuari anuncis personalitzats en altres llocs web.</p>
      <p></p>
    </li>
    <li>
      <p>Quadre resum de cookies utilitzades en el Lloc web</p>
      <p><strong>GRUPO EDELVIVES</strong> utilitza cookies per a personalitzar la navegació de l'usuari pels Llocs web de la seua titularitat, cookies d'entrada d'usuari i cookies de sessió per a equilibrar la càrrega, les quals estan excloses de l'àmbit d'aplicació de l'article 22.2 de la LSSI. </p>
      <p>D'altra banda, <strong>GRUPO EDELVIVES</strong> utilitza cookies d'analítica web per a mesurar i analitzar la navegació dels usuaris en els Llocs web. <strong>GRUPO EDELVIVES</strong> utilitza les dades obtingudes amb la finalitat d'introduir millores en funció de l'anàlisi de les dades d'ús que fan els usuaris. L'analítica web no permet obtindre informació sobre el nom, cognoms o adreça de correu electrònic o postal de l'usuari. La informació obtinguda és la relativa al nombre d'usuaris que accedeixen a la web, el nombre de pàgines vistes, la freqüència i repetició de les visites, la duració, el navegador utilitzat, l'operador que presta el servei, l'idioma, el terminal utilitzat o la ciutat a la qual està assignada l'adreça IP. De la mateixa manera, <strong>GRUPO EDELVIVES</strong> utilitza cookies de publicitat comportamental per a gestionar els espais publicitaris sobre la base de criteris determinats i cookies de complement (plug-in) per a intercanviar continguts socials. Aquestes cookies emmagatzemen informació del comportament dels usuaris obtinguda a través de l'observació continuada dels seus hàbits de navegació, la qual cosa permet mostrar publicitat en funció d'aquest.</p>
      <table>
        <thead>
          <tr>
            <td>NOMBRE</td>
            <td>TITULAR</td>
            <td>FINALIDAD</td>
            <td>DURACIÓN</td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td><strong>_ga</strong></td>
            <td>Google</td>
            <td>Aquesta cookie d'origen conté un identificador anònim usat per a distingir usuaris</td>
            <td>2 anys o actualització</td>
          </tr>
          <tr>
            <td><strong>_gat</strong></td>
            <td>Google</td>
            <td>Aquesta cookie d'origen conté un identificador per a diferenciar entre els objectes de seguiment creats en la sessió.	</td>
            <td>10 minuts des de creació o modificació</td>
          </tr>
          <tr>
            <td><strong>_gid</strong></td>
            <td>Google</td>
            <td>questa cookie s'usa per a distingir usuaris.</td>
            <td>24 horas</td>
          </tr>
          <tr>
            <td><strong>NID</strong></td>
            <td>Google</td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td><strong>G_ENABLED_IDPS</strong></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td><strong>ci_session_i2c</strong></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td><strong>SIDCC</strong></td>
            <td>Google</td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td><strong>OCAK</strong></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td><strong>GAPS</strong></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td><strong>CheckConnectionTempCookie529</strong></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td><strong>clusterBAK</strong></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td><strong>clusterD</strong></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td><strong>edelvives</strong></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td><strong>PrestaShop-b78d98b21a70688c13d5c9c7f255a804</strong></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td><strong>PHPSESSID</strong></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td><strong>endprom</strong></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
        </tbody>
      </table>
    </li>
    <li>
      <p>Configuració de l'usuari per a evitar cookies </p>
      <p>En compliment de la normativa legal vigent, <strong>GRUPO EDELVIVES</strong> posa a disposició dels usuaris del Lloc web la informació que els permeta configurar el seu navegador/navegadors d'internet per a mantindre la seua privacitat i seguretat en relació amb les cookies. Per això, es facilita la informació i els enllaços als llocs de suport oficials dels principals navegadors perquè cada usuari puga decidir si vol o no acceptar l'ús de cookies. </p>
      <p>Així, és possible bloquejar lescookies a través de les eines de configuració del navegador, o bé, cada usuari pot configurar el seu navegador perquè l’avise quan un servidor vulga guardar una cookie:</p>
      <ol style="list-style-type: lower-alpha;">
        <li>
          <p>Si utilitza Microsoft Internet Explorer, trobarà l'opció en el menú Eines > Opcions d'Internet > Privadesa > Configuració. Per a saber-ne més, visite <a target="_blank" href="http://windows.microsoft.com/es-es/windows-vista/block-or-allow-Cookies">http://windows.microsoft.com/es-es/windows-vista/block-or-allow-Cookies</a>
            y <a target="_blank" href="http://windows.microsoft.com/es-es/windows7/how-to-manage-Cookies-in-internet-explorer-9">http://windows.microsoft.com/es-es/windows7/how-to-manage-Cookies-in-internet-explorer-9</a>.</p>
        </li>
        <li>
          <p>Si utilitza Firefox, trobarà l'opció en el menú Eines > Opcions > Privadesa > Cookies. Per a saber-ne més, visite <a target="_blank" href="http://support.mozilla.org/es/kb/habilitar-y-deshabilitar-Cookies-que-los-sitios-we">http://support.mozilla.org/es/kb/habilitar-y-deshabilitar-Cookies-que-los-sitios-we</a>.</p>
        </li>
        <li>
          <p>Si utilitza Chrome, en la secció d'Opcions > Opcions avançades > Privadesa. Per a saber-ne més, visite <a target="_blank" href="https://support.google.com/accounts/answer/61416?hl=es">https://support.google.com/accounts/answer/61416?hl=es</a></p>
        </li>
        <li>
          <p>Si utilitza Opera, en l'opció de Seguretat i Privadesa, podrà configurar el navegador. Per a saber-ne més, visite
            <a target="_blank" href="http://help.opera.com/Windows/11.50/es-ES/cookies.html">http://help.opera.com/Windows/11.50/es-ES/cookies.html</a></p>
        </li>
        <li>
          <p>Si utilitza Safari trobarà l'opció en el menú Preferències/Privadesa. Més informació en: <a target="_blank" href="http://support.apple.com/kb/HT1677?viewlocale=es_ES">http://support.apple.com/kb/HT1677?viewlocale=es_ES</a></p>
        </li>
      </ol>
    </li>
  </ol>
  <p>Última actualització: 21 de gener de 2019.</p>

  
  </div>
  </div></div>`,
  es: '/assets/conditions/aviso-legal-24-es.pdf',
};

export const COOKIES = {
  es: `<div class="ccokies">
  <p>Esta Política de Cookies es parte integrante del Aviso Legal y la Política de Privacidad de la página web <a target="_blank" href="https://edelvivesdigitalplus.com/">https://edelvivesdigitalplus.com/</a> (en adelante, el <strong>“Sitio
      web”</strong>).El acceso y la navegación en el Sitio Web, o el uso de los servicios del mismo, implican la
    aceptación de los términos y condiciones recogido en el Aviso Legal y en la Política de Privacidad.</p>
  <p>Con el fin de facilitar la navegación por el Sitio Web, <strong>GRUPO EDITORIAL LUIS VIVES</strong>, como titular
    del mismo (en adelante, <strong>“GRUPO EDELVIVES”</strong>) con domicilio social en Ctra. de Madrid km. 315,700,
    50.012, Zaragoza, con N.I.F. R 5000274 J, le comunica que utiliza cookies u otros dispositivos de almacenamiento de
    almacenamiento y recuperación de datos de funcionalidad similar (en adelante, las <strong>“Cookies”</strong>).</p>
  <p>En este sentido y con el objetivo de garantizar al usuario toda la información necesaria para su correcta
    navegación, ponemos a disposición del usuario el siguiente texto informativo sobre qué son las cookies, qué
    tipología de cookies existen en nuestra Página Web y cómo es posible configurarlas o deshabilitarlas.</p>
  <ol>
    <li>
      <p>¿Qué es una Cookie?</p>
      <p>Las Cookies son archivos que contienen pequeñas cantidades de información que se descargan en el dispositivo del
        usuario cuando visita una página web. Su finalidad principal es reconocer al usuario cada vez que accede al Sitio
        Web permitiendo, además, mejorar la calidad y ofrecer un mejor uso del Sitio Web.</p>
      <p>Las Cookies son esenciales para el funcionamiento de Internet, ya que ayudan, entre otras funciones, a
        identificar y resolver posibles errores de funcionamiento del Sitio Web.</p>
    </li>
    <li>
      <p>Tipología de cookies</p>
      <p>En el presente Sitio Web se utilizan cookies propias y de terceros. Las cookies utilizadas se almacenan en su
        ordenador durante un tiempo determinado, éstas pueden considerarse cookies de sesión (si caducan cuando abandona
        nuestro Sitio Web) o persistentes (si la caducidad de las cookies dura más tiempo). Las cookies utilizadas en la
        presente página Web son:</p>
      <p><strong>Cookies “estrictamente necesarias”</strong> Son aquellas cookies consideradas imprescindibles para la
        navegación por la Página Web, pues facilitan al usuario la utilización de sus prestaciones o herramientas como,
        por ejemplo, identificar la sesión, acceder a partes de acceso restringido, recordar los elementos que integran un
        pedido, realizar el proceso de compra de un pedido, entre otros. Sin estas cookies, las funciones mencionadas
        quedarían inhabilitadas.</p>
      <p><strong>Cookies sobre comportamiento</strong>
        </p><p>Son aquellas cookies cuyo objetivo es la recopilación de información relativa a los usos de navegación del
          usuario, como por ejemplo las páginas que son más visitadas, los mensajes de error, entre otros, con el objetivo
          por parte del responsable, de introducir mejoras en la página web en base a la información recopilada por estas
          cookies.</p>
        <p>La información recopilada por estas cookies es anónima no pudiendo identificar al usuario personalmente y, por
          lo tanto, se utiliza exclusivamente para el correcto funcionamiento de la página web.</p>
      <p></p>
      <p><strong>Cookies de funcionalidad</strong>
        </p><p>Son aquellas cookies que permiten recordar a la página web las decisiones que el usuario toma, por ejemplo: se
          almacenará la ubicación geográfica del usuario para asegurar que se muestra el sitio web indicado para su región,
          el nombre de usuario, el idioma, el tipo de navegador mediante el cual se accede a la página web, entre otros. El
          objetivo es la prestación de un servicio más personalizado.</p>
        <p>La información recopilada por estas cookies es anónima no pudiendo identificar al usuario personalmente y, por
          lo tanto, se utiliza exclusivamente para el funcionamiento de la página web.</p>
      <p></p>
      <p><strong>Cookies de personalización y de publicidad</strong>
        </p><p>Son aquellas cookies que se utilizan por los operadores publicitarios con el permiso del titular de la página
          web, las cuales recogen información sobre las preferencias y elecciones del usuario en su navegación por sitios
          web. Se dirigen a las redes de publicidad quienes las utilizan para luego mostrar al usuario anuncios
          personalizados en otros sitios web.</p>
      <p></p>
    </li>
    <li>
      <p>Cuadro resumen de cookies utilizadas en el Sitio Web</p>
      <p><strong>GRUPO EDELVIVES</strong> utiliza cookies para personalizar la navegación del usuario por los Sitios Web
        de su titularidad, cookies de entrada de usuario y cookies de sesión para equilibrar la carga, las cuales están
        excluidas del ámbito de aplicación del artículo 22.2 de la LSSI.</p>
      <p>Por otra parte, <strong>GRUPO EDELVIVES</strong> utiliza cookies de analítica web para medir y analizar la
        navegación de los usuarios en los Sitios Web. <strong>GRUPO EDELVIVES</strong> utiliza los datos obtenidos con el
        fin de introducir mejoras en función del análisis de los datos de uso que hacen los usuarios. La analítica web no
        permite obtener información sobre el nombre, apellidos o dirección de correo electrónico o postal del usuario. La
        información obtenida es la relativa al número de usuarios que acceden a la web, el número de páginas vistas, la
        frecuencia y repetición de las visitas, su duración, el navegador utilizado, el operador que presta el servicio,
        el idioma, el terminal utilizado o la ciudad a la que está asignada la dirección IP.</p>
      <p>Del mismo modo, <strong>GRUPO EDELVIVES</strong> utiliza cookies de publicidad comportamental para la gestión de
        los espacios publicitarios en base a criterios determinados y cookies de complemento (plug-in) para intercambiar
        contenidos sociales. Estas cookies almacenan información del comportamiento de los usuarios obtenida a través de
        la observación continuada de sus hábitos de navegación, lo que permite mostrar publicidad en función del mismo.</p>
      <table>
        <thead>
          <tr>
            <td>NOMBRE</td>
            <td>TITULAR</td>
            <td>FINALIDAD</td>
            <td>DURACIÓN</td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td><strong>_ga</strong></td>
            <td>Google</td>
            <td>Esta cookie de origen contiene un identificador anónimo usado para distinguir usuarios.</td>
            <td>2 años o actualización</td>
          </tr>
          <tr>
            <td><strong>_gat</strong></td>
            <td>Google</td>
            <td>Esta cookie de origen contiene un identificador para diferenciar entre los diferentes objetos de seguimiento
              creados en la sesión.</td>
            <td>10 minutos desde creación o modificación</td>
          </tr>
          <tr>
            <td><strong>_gid</strong></td>
            <td>Google</td>
            <td>Esta cookie se usa para distinguir usuarios.</td>
            <td>24 horas</td>
          </tr>
          <tr>
            <td><strong>NID</strong></td>
            <td>Google</td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td><strong>G_ENABLED_IDPS</strong></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td><strong>ci_session_i2c</strong></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td><strong>SIDCC</strong></td>
            <td>Google</td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td><strong>OCAK</strong></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td><strong>GAPS</strong></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td><strong>CheckConnectionTempCookie529</strong></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td><strong>clusterBAK</strong></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td><strong>clusterD</strong></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td><strong>edelvives</strong></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td><strong>PrestaShop-b78d98b21a70688c13d5c9c7f255a804</strong></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td><strong>PHPSESSID</strong></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td><strong>endprom</strong></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
        </tbody>
      </table>
    </li>
    <li>
      <p>Configuración del usuario para evitar Cookies</p>
      <p>En cumplimiento de la normativa legal vigente, <strong>GRUPO EDELVIVES</strong> ppone a disposición de los
        usuarios del Sitio Web la información que les permita configurar su navegador/navegadores de Internet para
        mantener su privacidad y seguridad en relación con las Cookies. Por ello, se facilita la información y los enlaces
        a los sitios de soporte oficiales de los principales navegadores para que cada usuario pueda decidir si desea o no
        aceptar el uso de Cookies.</p>
      <p>Así, es posible bloquear las Cookies a través de las herramientas de configuración del navegador, o bien, cada
        usuario puede configurar su navegador para que le avise cuando un servidor quiera guardar una Cookie:</p>
      <ol style="list-style-type: lower-alpha;">
        <li>
          <p>Si utiliza Microsoft Internet Explorer, encontrará la opción en el menú Herramientas &gt; Opciones de Internet &gt;
            Privacidad &gt; Configuración. Para saber más visite <a target="_blank" href="http://windows.microsoft.com/es-es/windows-vista/block-or-allow-Cookies">http://windows.microsoft.com/es-es/windows-vista/block-or-allow-Cookies</a>
            y <a target="_blank" href="http://windows.microsoft.com/es-es/windows7/how-to-manage-Cookies-in-internet-explorer-9">http://windows.microsoft.com/es-es/windows7/how-to-manage-Cookies-in-internet-explorer-9</a>.</p>
        </li>
        <li>
          <p>Si utiliza Firefox, encontrará la opción en el menú Herramientas &gt; Opciones &gt; Privacidad &gt; Cookies. Para
            saber más visite <a target="_blank" href="http://support.mozilla.org/es/kb/habilitar-y-deshabilitar-Cookies-que-los-sitios-we">http://support.mozilla.org/es/kb/habilitar-y-deshabilitar-Cookies-que-los-sitios-we</a>.</p>
        </li>
        <li>
          <p>Si utiliza Chrome, en la sección de Opciones &gt; Opciones avanzadas &gt; Privacidad. Para saber más <a target="_blank" href="https://support.google.com/accounts/answer/61416?hl=es">https://support.google.com/accounts/answer/61416?hl=es</a></p>
        </li>
        <li>
          <p>Si utiliza Opera, en la opción de Seguridad y Privacidad, podrá configurar el navegador. Para saber más visite
            <a target="_blank" href="http://help.opera.com/Windows/11.50/es-ES/cookies.html">http://help.opera.com/Windows/11.50/es-ES/cookies.html</a></p>
        </li>
        <li>
          <p>Si utiliza Safari encontrará la opción en el menú Preferencias/Privacidad. Más información en: <a target="_blank" href="http://support.apple.com/kb/HT1677?viewlocale=es_ES">http://support.apple.com/kb/HT1677?viewlocale=es_ES</a></p>
        </li>
      </ol>
    </li>
  </ol>
  <p>Última actualización: 21 de enero de 2019.</p>
</div>
</div></div>`,
};

export const PRIVACY = {
  es: '/assets/conditions/privacidad-24-es.pdf',
  vl: `<div id="tyc"><div class="tyc_legal"><h3 id="privacy-policy"><a name="punto3a"></a>POLÍTICA DE PRIVACITAT </h3>
  <ol>
    <li>
      <h3>IDENTIFICACIÓ</h3>
      <ul>
        <li>Identitat: <strong>GRUPO  EDITORIAL LUIS VIVES</strong> VIVES (d'ara en avant, «GRUPO EDELVIVES»)</li>
        <li>Domicilio: Carretera de Madrid, Km 315,700, 50001-Zaragoza</li>
        <li>NIF: R5000274J</li>
        <li>Delegat de Protecció de Dades (DPO): pot contactar amb el nostre DPO a través dels següents mitjans:
          - E-mail: dpo@edelvives.es
          - Adreça: Carrer  Xaudaró, 25, 28034 Madrid. . Indique en la referència «Delegat de Protecció de Dades». </li>
      </ul>
    </li>
    <li>
      <h3>INFORMACIÓ I CONSENTIMENT.</h3>
      <p>Mitjançant l'acceptació de la present Política de Privacitat, l'usuari queda informat i presta el seu consentiment lliure, informat, específic i inequívoc perquè les dades personals que facilite a través de la pàgina web situada en l'URL <a target="_blank" href="https://edelvivesdigitalplus.com/">https://edelvivesdigitalplus.com/</a>(d'ara en avant, el «Lloc web») siguen trarctades per GRUPO EDELVIVES, així com les dades derivades de la navegació i aquelles altres dades que puga facilitar en un futur a GRUPO EDELVIVES. </p>
      <p>L'usuari ha de llegir amb atenció aquesta Política de Privacitat, que s’ha redactat de manera clara i senzilla, per a facilitar-ne la comprensió, i ha de determinar lliurement i voluntàriament si desitja facilitar les seues dades personals a GRUPO EDELVIVES. Els MENORS D'EDAT, només poden aportar dades personals amb el previ consentiment de pares o tutors, que s’ha d’enviar, degudament signat i amb còpia del DNI del pare, mare o tutor signant, a l'adreça rgpd@edelvives.es GRUPO EDELVIVES no respon de les dades de menors que, sense poder conéixer aquest fet, s'hagen facilitat sense consentiment de pare, mare o tutor.</p>
    </li>
    <li>
      <h3>OBLIGATORIETAT DE FACILITAR LES DADES. </h3>
      <p>Les dades sol·licitades en els formularis del Lloc web són, amb caràcter general, obligatòries (llevat que en el camp requerit s'especifique el contrari) per a complir amb les finalitats establides. Aquestes finalitats no podran atendre’s si no es faciliten les dades o no es faciliten, sense perjudici que l’usuari puga visualitzar lliurement el contingut del Lloc web. </p>
    </li>
    <li>
      <h3>AMB QUINA FINALITAT TRACTARÀ <strong>GRUPO  EDELVIVES</strong> LES DADES PERSONALS DE L'USUARI? </h3>
      <p>Les dades personals facilitades a través del Lloc web seran tractades per GRUPO EDELVIVES conforme a les següents finalitats:</p>
      <ol>
        <li>Dades facilitades per al registre en el Portal d'usuari:
          <ul>
            <li>
              <p>Gestionar el registre com a usuari en la plataforma en línia propietat de <strong>GRUPO EDELVIVES</strong> i allotjada en el present Lloc web.</p>
            </li>
            <li>
              <p>Gestionar el contingut del Portal.</p>
            </li>
            <li>
              <p>Efectuar anàlisis sobre la utilització del Lloc web i comprovar les preferències i comportament dels usuaris.</p>
            </li>
          </ul>
        </li>
        <li>
          <p>Dades facilitades en els formularis de contacte i altres canals posats a la disposició dels usuaris:</p>
          <ul>
            <li>
              <p>Gestionar les sol·licituds de contacte i informació de l'usuari a través dels canals disposats per a això en els llocs web de GRUPO EDELVIVES.</p>
            </li>
            <li>
              <p>Gestió de la petició plantejada.</p>
            </li>
            <li>
              <p>Efectuar anàlisis sobre la utilització del Lloc web i comprovar les preferències i comportament dels usuaris. </p>
            </li>
          </ul>
        </li>
      </ol>
    </li>
    <li>
      <h3>QUINES DADES DE L'USUARI TRACTARÀ GRUPO  EDELVIVES?</h3>
      <p>GRUPO EDELVIVES tractarà les següents categories de dades de l'usuari:</p>
      <ol>
        <li>
          <p>Dades facilitades per al registre en el <strong><u>Portal d'usuari </u></strong></p>
          <ul>
            <li>
              <p>Dades identificatives: nom, cognoms.</p>
            </li>
            <li>
              <p>Dades de contacte: domicili, adreça de correu electrònic, número de telèfon.</p>
            </li>
            <li>
              <p>Altres dades: dades facilitades pels interessats en els camps oberts.</p>
            </li>
            <li>
              <p>Dades de navegació.</p>
            </li>
          </ul>
        </li>
        <li>
          <p>Dades facilitades en els <strong><u>formularis de contacte i altres canals posats a la disposició dels usuaris</u></strong>:</p>
          <ul>
            <li>
              <p>Dades identificatives: nom, cognoms.</p>
            </li>
            <li>
              <p>Dades de contacte: adreça de correu electrònic, número de telèfon.</p>
            </li>
            <li>
              <p>Dades de transacció de béns i serveis.</p>
            </li>
            <li>
              <p>Altres dades: dades facilitades pels interessats en els camps oberts dels formularis disposats en el Lloc web o en els documents adjunts.</p>
            </li>
            <li>
              <p>Dades de navegació. </p>
            </li>
          </ul>
          <p>En cas que l'usuari facilite dades de tercers, manifesta comptar amb el consentiment dels mateixos i es compromet a traslladar-li la informació continguda en la Política de Privacitat. Eximeix així <strong>GRUPO EDELVIVES</strong> de qualsevol responsabilitat en aquest sentit. No obstant això, <strong>GRUPO EDELVIVES</strong> podrà dur a terme les verificacions periòdiques per a constatar aquest fet i adoptar les mesures de diligència deguda que correspongue conforme a la normativa de protecció de dades.</p>
        </li>
      </ol>
    </li>
    <li>
      <h3>QUINA ÉS LA LEGITIMACIÓ DEL TRACTAMENT DE LES DADES DE L'USUARI?</h3>
      <p>La legitimació del tractament de les seues dades personals, seran les següents:</p>
      <ol>
        <li>
          <p>Dades facilitades per al registre en el Portal d'usuari: el consentiment que se li sol·licita i que pot retirar en qualsevol moment. No obstant això, en cas de retirar el seu consentiment, això no afectarà la licitud dels tractaments efectuats amb anterioritat. Per a l'execució d'anàlisis sobre la utilització de la web, l'interés legítim GRUPO EDELVIVES.</p>
        </li>
        <li>
          <p>Per als formularis de contacte i altres canals posats a la disposició dels usuaris: el consentiment que se li sol·licita i que pot retirar en qualsevol moment. No obstant això, en cas de retirar el seu consentiment, això no afectarà la licitud dels tractaments efectuats amb anterioritat. Per a l'execució d'anàlisis sobre la utilització de la web, l'interés legítim de GRUPO  EDELVIVES.</p>
        </li>
        <li>
        <p>Per a la gestió del seu usuari i les incidències tècniques que hipotèticament pogueren produir-se en la Plataforma, GRUPO EDELVIVES es troba legitimat legalment per al tractament dels dades personals dels usuaris d'acord amb l'execució el corresponent contracte de prestació dels serveis, tal com s'indica a l'Avís Legal i a la present Política de Privadesa. </p>
      </li>
      </ol>
      <p>Els consentiments obtinguts per a les finalitats esmentades són independents; l'usuari podrà revocar només un d'ells sense afectar els altres.</p>
      <p>Per a revocar aquest consentiment, l'Usuari podrà contactar amb GRUPO  EDELVIVES, en qualsevol moment, a través dels canals següents: <a target="_blank" href="mailto:rgpd@edelvives.es">rgpd@edelvives.es</a>,
      indicant en l'assumpte de referència «Dades Personals».</p>
    </li>
    <li>
      <h3>AMB QUINS DESTINATARIS ES COMPARTIRAN LES DADES DE L'USUARI?</h3>
      <p>Les dades de l'usuari podran ser comunicar-se a:</p>
      <ol>
        <li>
          <p>Dades facilitades per al registre en el Portal d'usuari: Empreses del grup al qual pertany GRUPO  EDELVIVES, únicament per a fins administratius interns i/o per a les finalitats anteriorment indicades.</p>
        </li>
        <li>
          <p>Dades facilitades per als formularis de contacte i altres canals posats a la disposició dels usuaris: Empreses del grup al qual pertany GRUPO EDELVIVES, únicament per a fins administratius interns i/o per a les finalitats anteriorment indicades.</p>
        </li>
      </ol>
      <p>Addicionalment, les dades podran ser accessibles per proveïdors de GRUPO  EDELVIVES. Aquest accés serà el necessari per a l'adequat compliment de les obligacions legals i/o de les finalitats anteriorment indicades. Aquests proveïdors no tractaran les seues dades per a finalitats pròpies que no hagen sigut prèviament informades per GRUPO EDELVIVES.</p>
    </li>
    <li>
      <h3>CONSERVACIÓ DE LES DADES</h3>
      <p>Les seues dades seran conservades durant els terminis següents:</p>
      <ol>
        <li>
          <p>Dades facilitades per al registre en el Portal d'usuari: seran conservades fins que l'usuari sol·licite la baixa i, una vegada sol·licitada, durant el termini de prescripció d'accions legals derivades de l'ús d'aquesta.</p>
        </li>
        <li>
          <p>Dades facilitades per als formularis de contacte i altres canals posats a la disposició dels usuaris: seran conservades durant el termini necessari per a donar tràmit i contestació a la sol·licitud i, una vegada finalitzat, durant el termini de prescripció de les accions legals derivades de la citada sol·licitud.</p>
        </li>
      </ol>
    </li>
    <li>
      <h3>RESPONSABILITAT DE L'USUARI.</h3>
      <p>L'usuari:</p>
      <ul>
        <li>
          <p>Garanteix que és major de díhuit (18) anys i que les dades que facilita a <strong>GRUPO  EDELVIVES</strong> són vertaderes, exactes, completes i actualitzades. A aquest efecte, l'usuari respon de la veracitat de totes les dades que comunique i mantindrà convenientment actualitzada la informació facilitada, de tal forma que responga a la seua situació real.</p>
        </li>
        <li>
          <p>Garanteix que ha informat els tercers dels quals en facilite les dades, en cas de fer-ho, dels aspectes continguts en aquest document. Així mateix, garanteix que ha obtingut l’autorització per a facilitar aquestese dades a <strong>GRUPO EDELVIVES</strong> per als fins assenyalats.</p>
        </li>
        <li>
          <p>Serà responsable de les informacions falses o inexactes que proporcione a través del Lloc web i dels danys i perjudicis, directes o indirectes, que això cause a <strong>GRUPO EDELVIVES</strong> o a tercers.</p>
        </li>
      </ul>
    </li>
    <li>
      <h3>EXERCICI DE DRETS.</h3>
      <p>L'usuari pot enviar un escrit a GRUPO EDELVIVES, a l'adreça indicada en l'encapçalament de la present Política, o bé per mitjà d'un correu electrònic a l'adreça <a target="_blank" href="mailto:rgpd@edelvives.es">rgpd@edelvives.es</a>,
      adjuntant fotocòpia del document d'identitat, en qualsevol moment i de manera gratuïta, per a:</p>
      <ul>
        <li>
          <p>Revocar els consentiments atorgats.</p>
        </li>
        <li>
          <p>Obtindre confirmació sobre si en <strong>GRUPO EDELVIVES</strong> s'estan tractant dades personals que concerneixen l'usuari o no.</p>
        </li>
        <li>
          <p>Accedir a les seues dades personals.</p>
        </li>
        <li>
          <p>Rectificar les dades inexactes o incompletes.</p>
        </li>
        <li>
          <p>Sol·licitar la supressió de les seues dades quan, entre altres motius, les dades ja no siguen necessàries per als fins pels quals van ser recollides.</p>
        </li>
        <li>
          <p>Obtindre de <strong>GRUPO EDELVIVES</strong> la limitació del tractament de les dades quan es complisca alguna de les condicions previstes en la normativa de protecció de dades.</p>
        </li>
        <li>
          <p>Sol·licitar la portabilitat de les dades facilitades per l'Usuari en aquells casos previstos en la normativa</p>
        </li>
        <li>
          <p>Posar-se en contacte amb el DPO de <strong>GRUPO EDELVIVES</strong> a través de la següent adreça: <a target="_blank" href="mailto:dpo@edelvives.es">dpo@edelvives.es</a></p>
        </li>
        <li>
          <p>Interposar una reclamació relativa a la protecció de les seues dades personals davant l'Agència Espanyola de Protecció de Dades en l'adreça Carrer de Jorge Juan, 6, 28001 Madrid, quan l'interessat considere que <strong>GRUPO EDELVIVES</strong> ha vulnerat els drets que li són reconeguts per la normativa aplicable en protecció de dades.</p>
        </li>
      </ul>
    </li>
    <li>
      <h3>MESURES DE SEGURETAT.</h3>
      <p>GRUPO EDELVIVES tractarà les dades de l'usuari, en tot moment, de forma absolutament confidencial i guardant el preceptiu deure de secret respecte d'aquestes, de conformitat amb el que es preveu en la normativa d'aplicació. Adoptarà a aquest efecte les mesures d'índole tècnica i organitzativa necessàries per a garantir la seguretat de les seues dades i evitar-ne l’alteració, pèrdua, tractament o accés no autoritzat, tenint en compte l'estat de la tecnologia, la naturalesa de les dades emmagatzemades i dels riscos als quals s’exposen. </p>
      <p>Última actualització: 21 de gener de 2019. </p>
    </li>
  </ol>
  </div>
  <div class="ccokies">
      <h3><a name="punto3a"></a>POLÍTICA DE COOKIES</h3>
      <p>Aquesta Política de Cookies és part integrant de l'Avís Legal i la Política de Privacitat de la pàgina web https://edelvivesdigitalplus.com/ (d'ara en avant, el «Lloc web»). L'accés i la navegació en el Lloc web, o l'ús dels serveis d'aquest, impliquen l'acceptació dels termes i condicions recollits en l'Avís Legal i en la Política de Privacitat. 
      Amb l’objectiu de facilitar la navegació pel Lloc web, GRUPO  EDITORIAL LUIS VIVES, com a titular del mateix (d'ara en avant, «GRUPO  EDELVIVES») amb domicili social en Ctra. de Madrid km. 315,700, 50.012, Saragossa, amb NIF R 5000274 J, li comunica que utilitza cookies o altres dispositius d'emmagatzematge i recuperació de dades de funcionalitat similar (d'ara en avant, les «cookies»). En aquest sentit, i amb l'objectiu de garantir a l'usuari tota la informació necessària per a la correcta navegació, posem a la disposició de l'usuari el següent text informatiu sobre què són les cookies, quina tipologia de cookiies existeixen en la nostra Pàgina Web i com és possible configurar-les o deshabilitar-les. 
      </p>
      <ol>
        <li>
          <p>Què és una cookie?</p>
          <p> Les cookies són arxius que contenen xicotetes quantitats d'informació que es descarreguen en el dispositiu de l'usuari quan visita una pàgina web. La seua finalitat principal és reconéixer l'usuari cada vegada que accedeix al Lloc web per a millorar la qualitat i oferir un millor ús del Lloc web. Les cookies són essencials per al funcionament d'internet, ja que ajuden, entre altres funcions, a identificar i resoldre possibles errors de funcionament del Lloc web. </p>
          <p>Las Cookies son esenciales para el funcionamiento de Internet, ya que ayudan, entre otras funciones, a
            identificar y resolver posibles errores de funcionamiento del Sitio Web.</p>
        </li>
        <li>
          <p>Tipologia de cookies</p>
          <p>En el present Lloc web s'utilitzen cookies pròpies i de tercers. Les cookies utilitzades s'emmagatzemen en el seu ordinador durant un temps determinat, aquestes poden considerar-se cookies de sessió (si caduquen quan abandona el nostre Lloc web) o persistents (si la caducitat de les cookies dura més temps). Les cookies utilitzades en la present pàgina Web són: </p>
          <p><strong>Cookies “estrictament necessàries”</strong> Són aquelles cookies considerades imprescindibles per a la navegació per la Pàgina Web, perquè faciliten a l'usuari la utilització de les seues prestacions o eines com, per exemple, identificar la sessió, accedir a parts d'accés restringit, recordar els elements que integren una comanda, realitzar el procés de compra d'una comanda, entre d’altres. Sense aquestes cookies, les funcions esmentades quedarien inhabilitades. </p>
          <p><strong>Cookies sobre comportament </strong>
            </p><p>Són aquelles cookies l'objectiu de les quals és recopilar informació relativa als usos de navegació de l'usuari, com per exemple les pàgines que són més visitades o els missatges d'error, entre d’altres, amb l'objectiu per part del responsable, d'introduir millores en la pàgina web a partir de la informació recopilada per aquestes cookies.</p>
            <p>La informació recopilada per aquestes cookies és anònima, no permet identificar l'usuari personalment i, per tant, s'utilitza exclusivament per al correcte funcionament de la pàgina web. </p>
          <p></p>
          <p><strong>Cookies de funcionalitat </strong>
            </p><p>Són aquelles cookies que permeten recordar a la pàgina web les decisions que l'usuari pren, per exemple: s'emmagatzemarà la ubicació geogràfica de l'usuari per a assegurar que es mostra el lloc web indicat per a la seua regió, el nom d'usuari, l'idioma o el tipus de navegador mitjançant el qual s'accedeix a la pàgina web, entre d’altres. L'objectiu és la prestació d'un servei més personalitzat. La informació recopilada per aquestes cookies és anònima, no permet identificar l'usuari personalment i, per tant, s'utilitza exclusivament per al funcionament de la pàgina web. </p>
          <p></p>
          <p><strong>Cookies de personalització i de publicitat </strong>
            </p><p>Són aquelles cookies que utilitzen els operadors publicitaris amb el permís del titular de la pàgina web. Recullen informació sobre les preferències i eleccions de l'usuari en la seua navegació per llocs web i es dirigeixen a les xarxes de publicitat, que les utilitzen per a després mostrar a l'usuari anuncis personalitzats en altres llocs web.</p>
          <p></p>
        </li>
        <li>
          <p>Quadre resum de cookies utilitzades en el Lloc web</p>
          <p>GRUPO  EDELVIVES utilitza cookies per a personalitzar la navegació de l'usuari pels Llocs web de la seua titularitat, cookies d'entrada d'usuari i cookies de sessió per a equilibrar la càrrega, les quals estan excloses de l'àmbit d'aplicació de l'article 22.2 de la LSSI. </p>
          <p>D'altra banda,<strong>GRUPO  EDELVIVES</strong> utilitza cookies d'analítica web per a mesurar i analitzar la navegació dels usuaris en els Llocs web.<strong>GRUPO  EDELVIVES</strong> utilitza les dades obtingudes amb la finalitat d'introduir millores en funció de l'anàlisi de les dades d'ús que fan els usuaris. L'analítica web no permet obtindre informació sobre el nom, cognoms o adreça de correu electrònic o postal de l'usuari. La informació obtinguda és la relativa al nombre d'usuaris que accedeixen a la web, el nombre de pàgines vistes, la freqüència i repetició de les visites, la duració, el navegador utilitzat, l'operador que presta el servei, l'idioma, el terminal utilitzat o la ciutat a la qual està assignada l'adreça IP. De la mateixa manera,<strong>GRUPO  EDELVIVES</strong> utilitza cookies de publicitat comportamental per a gestionar els espais publicitaris sobre la base de criteris determinats i cookies de complement (plug-in) per a intercanviar continguts socials. Aquestes cookies emmagatzemen informació del comportament dels usuaris obtinguda a través de l'observació continuada dels seus hàbits de navegació, la qual cosa permet mostrar publicitat en funció d'aquest.</p>
          <table>
            <thead>
              <tr>
                <td>NOMBRE</td>
                <td>TITULAR</td>
                <td>FINALIDAD</td>
                <td>DURACIÓN</td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td><strong>_ga</strong></td>
                <td>Google</td>
                <td>Aquesta cookie d'origen conté un identificador anònim usat per a distingir usuaris</td>
                <td>2 años o actualización</td>
              </tr>
              <tr>
                <td><strong>_gat</strong></td>
                <td>Google</td>
                <td>Aquesta cookie d'origen conté un identificador per a diferenciar entre els objectes de seguiment creats en la sessió.</td>
                <td>10 minuts des de creació o modificació</td>
              </tr>
              <tr>
                <td><strong>_gid</strong></td>
                <td>Google</td>
                <td>Aquesta cookie s'usa per a distingir usuaris.</td>
                <td>24 hores</td>
              </tr>
              <tr>
                <td><strong>NID</strong></td>
                <td>Google</td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td><strong>G_ENABLED_IDPS</strong></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td><strong>ci_session_i2c</strong></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td><strong>SIDCC</strong></td>
                <td>Google</td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td><strong>OCAK</strong></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td><strong>GAPS</strong></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td><strong>CheckConnectionTempCookie529</strong></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td><strong>clusterBAK</strong></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td><strong>clusterD</strong></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td><strong>edelvives</strong></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td><strong>PrestaShop-b78d98b21a70688c13d5c9c7f255a804</strong></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td><strong>PHPSESSID</strong></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td><strong>endprom</strong></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
            </tbody>
          </table>
        </li>
        <li>
          <p>Configuració de l'usuari per a evitar cookies </p>
          <p>En compliment de la normativa legal vigent,<strong>GRUPO  EDELVIVES</strong> posa a disposició dels usuaris del Lloc web la informació que els permeta configurar el seu navegador/navegadors d'internet per a mantindre la seua privacitat i seguretat en relació amb les cookies. Per això, es facilita la informació i els enllaços als llocs de suport oficials dels principals navegadors perquè cada usuari puga decidir si vol o no acceptar l'ús de cookies. </p>
          <p>Així, és possible bloquejar lescookies a través de les eines de configuració del navegador, o bé, cada usuari pot configurar el seu navegador perquè l’avise quan un servidor vulga guardar una cookie: </p>
          <ol style="list-style-type: lower-alpha;">
            <li>
              <p>Si utilitza Microsoft Internet Explorer, trobarà l'opció en el menú Eines > Opcions d'Internet > Privadesa > Configuració. Per a saber-ne més, visite <a target="_blank" href="http://windows.microsoft.com/es-es/windows-vista/block-or-allow-Cookies">http://windows.microsoft.com/es-es/windows-vista/block-or-allow-Cookies</a>
                i <a target="_blank" href="http://windows.microsoft.com/es-es/windows7/how-to-manage-Cookies-in-internet-explorer-9">http://windows.microsoft.com/es-es/windows7/how-to-manage-Cookies-in-internet-explorer-9</a>.</p>
            </li>
            <li>
              <p>Si utilitza Firefox, trobarà l'opció en el menú Eines > Opcions > Privadesa > Cookies. Per a saber-ne més, visite <a target="_blank" href="http://support.mozilla.org/es/kb/habilitar-y-deshabilitar-Cookies-que-los-sitios-we">http://support.mozilla.org/es/kb/habilitar-y-deshabilitar-Cookies-que-los-sitios-we</a>.</p>
            </li>
            <li>
              <p>Si utilitza Chrome, en la secció d'Opcions > Opcions avançades > Privadesa. Per a saber-ne més, visite https://support.google.com/accounts/answer/61416?hl=es <a target="_blank" href="https://support.google.com/accounts/answer/61416?hl=es">https://support.google.com/accounts/answer/61416?hl=es</a></p>
            </li>
            <li>
              <p>Si utilitza Opera, en l'opció de Seguretat i Privadesa, podrà configurar el navegador. Per a saber-ne més, visite <a target="_blank" href="http://help.opera.com/Windows/11.50/es-ES/cookies.html">http://help.opera.com/Windows/11.50/es-ES/cookies.html</a></p>
            </li>
            <li>
              <p>. Si utilitza Safari trobarà l'opció en el menú Preferències/Privadesa. Més informació en: <a target="_blank" href="http://support.apple.com/kb/HT1677?viewlocale=es_ES">http://support.apple.com/kb/HT1677?viewlocale=es_ES</a></p>
            </li>
          </ol>
        </li>
      </ol>
      <p>Última actualización: 21 de enero de 2019.</p>
    </div>
    </div></div>`,
};

// export const PRIVACY = {
//   es: `<div id="tyc"><div class="tyc_legal"><h3 id="privacy-policy"><a name="punto3a"></a>POLÍTICA DE PRIVACIDAD</h3>
// <ol>
//   <li>
//     <h3>IDENTIFICACIÓN</h3>
//     <ul>
//       <li>Identidad: <strong>GRUPO EDITORIAL LUIS VIVES</strong> (en adelante, <strong>"GRUPO EDELVIVES"</strong>)</li>
//       <li>Domicilio: Carretera de Madrid, Km 315,700, 50001-Zaragoza</li>
//       <li>NIF: R5000274J</li>
//       <li>Delegado de Protección de Datos (DPO): puede contactar con nuestro DPO a través de los siguientes medios: :
//         - E-mail: dpo@edelvives.es
//         - Dirección: Calle Xaudaró, 25, 28034 Madrid.</li>
//     </ul>Indicando en la referencia “Delegado de Protección de Datos”.
//   </li>
//   <li>
//     <h3>INFORMACIÓN Y CONSENTIMIENTO.</h3>
//     <p>Mediante la aceptación de la presente Política de Privacidad, el usuario queda informado y presta su
//       consentimiento libre, informado, específico e inequívoco para que los datos personales que facilite a través de la
//       página web ubicada en la URL <a target="_blank" href=${isEdelvivesMX() ? 'https://edelvivesdigitalplus.com.mx/' : 'https://edelvivesdigitalplus.com/'}>${
//     isEdelvivesMX() ? 'https://edelvivesdigitalplus.com.mx/' : 'https://edelvivesdigitalplus.com/'
//   }</a>
//       (en adelante, el <strong>“Sitio Web”</strong>) sean tratados por <strong>GRUPO EDELVIVES</strong>, así como los
//       datos derivados de
//       su navegación y aquellos otros datos que pueda facilitar en un futuro a <strong>GRUPO EDELVIVES</strong>.</p>
//     <p>El usuario debe leer con atención esta Política de Privacidad, que ha sido redactada de forma clara y sencilla,
//       para facilitar su comprensión, determinando libre y voluntariamente si desea facilitar sus datos personales a
//       <strong>GRUPO EDELVIVES</strong>.</p>
//     <p>Si eres MENOR DE EDAD, sólo puedes aportar datos personales con el previo consentimiento de padres o tutores,
//       enviado debidamente firmado y con copia del DNI del padre o tutor firmante a la dirección <a mailto="rgpd@edelvives.es">rgpd@edelvives.es</a>
//       <strong>GRUPO EDELVIVES</strong> no responde de los datos de menores que sin poder conocer este hecho, se hayan
//       facilitado sin
//       consentimiento de padre o tutor.</p>
//   </li>
//   <li>
//     <h3>OBLIGATORIEDAD DE FACILITAR LOS DATOS.</h3>
//     <p>Los datos solicitados en los formularios del Sitio Web son con carácter general, obligatorios (salvo que en el
//       campo requerido se especifique lo contrario) para cumplir con las finalidades establecidas.</p>
//     <p>Por lo tanto, si no se facilitan los mismos o no se facilitan correctamente no podrán atenderse las mismas, sin
//       perjuicio de que podrá visualizar libremente el contenido del Sitio Web.</p>
//   </li>
//   <li>
//     <h3>¿CON QUÉ FINALIDAD TRATARÁ <strong>GRUPO EDELVIVES</strong> LOS DATOS PERSONALES DEL USUARIO?</h3>
//     <p>Los datos personales facilitados a través del Sitio Web serán tratados por <strong>GRUPO EDELVIVES</strong>
//       conforme a las
//       siguientes finalidades:</p>
//     <ol>
//       <li>Datos facilitados para el registro en el <strong>Portal de usuario</strong>:
//         <ul>
//           <li>
//             <p>Gestionar su registro como usuario en la plataforma online propiedad de <strong>GRUPO EDELVIVES</strong> y
//               alojada en el presente Sitio Web. </p>
//           </li>
//           <li>
//             <p>Gestionar el contenido del Portal.</p>
//           </li>
//           <li>
//             <p>Efectuar análisis sobre la utilización del Sitio Web y comprobar las preferencias y comportamiento de los
//               usuarios.</p>
//           </li>
//         </ul>
//       </li>
//       <li>
//         <p>Datos facilitados en los <strong>formularios de contacto y otros canales puestos a disposición de los usuarios</strong>:</p>
//         <ul>
//           <li>
//             <p>Gestionar las solicitudes de contacto e información del usuario a través de los canales dispuestos para
//               ello en los sitios web de <strong>GRUPO EDELVIVES</strong>.</p>
//           </li>
//           <li>
//             <p>Gestión de la petición planteada.</p>
//           </li>
//           <li>
//             <p>Efectuar análisis sobre la utilización del Sitio Web y comprobar las preferencias y comportamiento de los
//               usuarios.</p>
//           </li>
//         </ul>
//       </li>
//     </ol>
//   </li>
//   <li>
//     <h3>¿QUÉ DATOS DEL USUARIO TRATARÁ GRUPO EDELVIVES? </h3>
//     <p><strong>GRUPO EDELVIVES</strong> tratará las siguientes categorías de datos del usuario:</p>
//     <ol>
//       <li>
//         <p>Datos facilitados para el registro en el <strong><u>Portal de usuario</u></strong></p>
//         <ul>
//           <li>
//             <p>Datos identificativos: nombre, apellidos.</p>
//           </li>
//           <li>
//             <p>Datos de contacto: domicilio, dirección de correo electrónico, número de teléfono.</p>
//           </li>
//           <li>
//             <p>Otros datos: datos facilitados por los propios interesados en los campos abiertos.</p>
//           </li>
//           <li>
//             <p>Datos de navegación.</p>
//           </li>
//         </ul>
//       </li>
//       <li>
//         <p>Datos facilitados en los <strong><u>formularios de contacto y otros canales puestos a disposición de los
//               usuarios</u></strong>:</p>
//         <ul>
//           <li>
//             <p>Datos identificativos: nombre, apellidos.</p>
//           </li>
//           <li>
//             <p>Datos de contacto: dirección de correo electrónico, número de teléfono.</p>
//           </li>
//           <li>
//             <p>Datos de transacción de bienes y servicios.</p>
//           </li>
//           <li>
//             <p>Otros datos: datos facilitados por los propios interesados en los campos abiertos de los formularios
//               dispuestos en el Sitio Web o en los documentos adjuntos.</p>
//           </li>
//           <li>
//             <p>Datos de navegación.</p>
//           </li>
//         </ul>
//         <p>En caso de que el usuario facilite datos de terceros, manifiesta contar con el consentimiento de los mismos y
//           se compromete a trasladarle la información contenida en la Política de Privacidad, eximiendo a <strong>GRUPO
//             EDELVIVES</strong>
//           de cualquier responsabilidad en este sentido. No obstante, <strong>GRUPO EDELVIVES</strong> podrá llevar a cabo
//           las
//           verificaciones periódicas para constatar este hecho, adoptando las medidas de diligencia debida que
//           correspondan, conforme a la normativa de protección de datos.</p>
//       </li>
//     </ol>
//   </li>
//   <li>
//     <h3>¿CUÁL ES LA LEGITIMACIÓN DEL TRATAMIENTO DE LOS DATOS DEL USUARIO?</h3>
//     <p>La legitimación del tratamiento de sus datos personales, serán las siguientes:</p>
//     <ol>
//       <li>
//         <p>Datos facilitados para el registro en el <stron><u>Portal de usuario</u></stron>: el consentimiento que se le
//           solicita y que puede
//           retirar en cualquier momento. No obstante, en caso de retirar su consentimiento, ello no afectará a la licitud
//           de los tratamientos efectuados con anterioridad. Para la ejecución de análisis sobre la utilización de la web,
//           el interés legítimo <strong>GRUPO EDELVIVES</strong>.</p>
//       </li>
//       <li>
//         <p>Para los <strong><u>formularios de contacto y otros canales puestos a disposición de los usuarios</u></strong>:
//           el consentimiento
//           que se le solicita y que puede retirar en cualquier momento. No obstante, en caso de retirar su consentimiento,
//           ello no afectará a la licitud de los tratamientos efectuados con anterioridad. Para la ejecución de análisis
//           sobre la utilización de la web, el interés legítimo de <strong>GRUPO EDELVIVES</strong>.</p>
//       </li>
//       <li>
//         <p>Para la <strong><u>gestión de su usuario y las incidencias técnicas</u></strong> que hipotéticamente pudieran producirse en la Plataforma, <strong>GRUPO EDELVIVES</strong> se encuentra legitimado legalmente para el tratamiento de los datos personales de los usuarios conforme a  la ejecución del correspondiente contrato de prestación de los servicios, tal y como se indica en el Aviso Legal y en la presente Política de Privacidad.</p>
//         </li>
//     </ol>
//     <p>Los consentimientos obtenidos para las finalidades mencionadas son independientes por lo que el usuario podrá
//       revocar solo uno de ellos no afectando a los demás.</p>
//     <p>Para revocar dicho consentimiento, el Usuario podrá contactar con <strong>GRUPO EDELVIVES</strong>, en cualquier
//       momento, a través de los canales siguientes: <a target="_blank" href="mailto:rgpd@edelvives.es">rgpd@edelvives.es</a>,
//       indicando en el asunto de referencia “Datos Personales”.</p>
//   </li>
//   <li>
//     <h3>¿CON QUÉ DESTINATARIOS SE COMPARTIRÁN LOS DATOS DEL USUARIO?</h3>
//     <p>Los datos del usuario podrán ser comunicados a:</p>
//     <ol>
//       <li>
//         <p>1. Datos facilitados para el registro en el <strong><u>Portal de usuario</u></strong>: Empresas del grupo al
//           que pertenece <strong>GRUPO
//             EDELVIVES</strong>, únicamente para fines administrativos internos y/o para las finalidades anteriormente
//           indicadas.</p>
//       </li>
//       <li>
//         <p>2. Datos facilitados para los <strong><u>formularios de contacto y otros canales puestos a disposición de los
//               usuarios</u></strong>:
//           Empresas del grupo al que pertenece <strong>GRUPO EDELVIVES</strong>, únicamente para fines administrativos
//           internos y/o para las
//           finalidades anteriormente indicadas.</p>
//       </li>
//     </ol>
//     <p>Adicionalmente, los datos podrán ser accesibles por proveedores de <strong>GRUPO EDELVIVES</strong>, siendo
//       dicho acceso el
//       necesario para el adecuado cumplimiento de las obligaciones legales y/o de las finalidades anteriormente
//       indicadas. Dichos proveedores no tratarán sus datos para finalidades propias que no hayan sido previamente
//       informadas por <strong>GRUPO EDELVIVES</strong>.</p>
//   </li>
//   <li>
//     <h3>CONSERVACIÓN DE LOS DATOS</h3>
//     <p>Sus datos serán conservados durante los siguientes plazos:</p>
//     <ol>
//       <li>
//         <p>Datos facilitados para el registro en el <strong><u>Portal de usuario</u></strong>: serán conservados hasta
//           que el usuario no solicite la baja de la misma y, una vez solicitada, durante el plazo de prescripción de
//           acciones legales derivadas del uso de la misma. </p>
//       </li>
//       <li>
//         <p>2. Datos facilitados para los <strong><u>formularios de contacto y otros canales puestos a disposición de los
//               usuarios</u></strong>:
//           serán conservados durante el plazo necesario para dar trámite y contestación a su solicitud y, una vez
//           finalizado el mismo, durante el plazo de prescripción de las acciones legales derivadas de la citada solicitud</p>
//       </li>
//     </ol>
//   </li>
//   <li>
//     <h3>RESPONSABILIDAD DEL USUARIO.</h3>
//     <p>El usuario:</p>
//     <ul>
//       <li>
//         <p>Garantiza que es mayor de dieciocho (18) años y que los datos que facilita a <strong>GRUPO EDELVIVES</strong>
//           son verdaderos, exactos, completos y actualizados. A estos efectos, el usuario responde de la veracidad de todos
//           los datos que comunique y mantendrá convenientemente actualizada la información facilitada, de tal forma que
//           responda a su situación real.</p>
//       </li>
//       <li>
//         <p>Garantiza que ha informado a los terceros de los que facilite sus datos, en caso de hacerlo, de los aspectos
//           contenidos en este documento. Asimismo, garantiza que ha obtenido su autorización para facilitar sus datos a
//           <strong>GRUPO EDELVIVES</strong> para los fines señalados.</p>
//       </li>
//       <li>
//         <p>Será responsable de las informaciones falsas o inexactas que proporcione a través del Sitio Web y de los daños
//           y perjuicios, directos o indirectos, que ello cause a <strong>GRUPO EDELVIVES</strong> o a terceros.</p>
//       </li>
//     </ul>
//   </li>
//   <li>
//     <h3>EJERCICIO DE DERECHOS.</h3>
//     <p>El usuario puede enviar un escrito a <strong>GRUPO EDELVIVES</strong>, a la dirección indicada en el encabezado
//       de la presente Política, o bien por medio de un correo electrónico a la dirección <a target="_blank" href="mailto:rgpd@edelvives.es">rgpd@edelvives.es</a>,
//       adjuntando fotocopia de su documento de identidad, en cualquier momento y de manera gratuita, para:</p>
//     <ul>
//       <li>
//         <p>Revocar los consentimientos otorgados.</p>
//       </li>
//       <li>
//         <p>Obtener confirmación acerca de si en <strong>GRUPO EDELVIVES</strong> se están tratando datos personales que
//           conciernen al usuario o no.</p>
//       </li>
//       <li>
//         <p>Acceder a sus datos personales.</p>
//       </li>
//       <li>
//         <p>Rectificar los datos inexactos o incompletos.</p>
//       </li>
//       <li>
//         <p>Solicitar la supresión de sus datos cuando, entre otros motivos, los datos ya no sean necesarios para los
//           fines que fueron recogidos.</p>
//       </li>
//       <li>
//         <p>Obtener de <strong>GRUPO EDELVIVES</strong> la limitación del tratamiento de los datos cuando se cumpla alguna
//           de las condiciones previstas en la normativa de protección de datos.</p>
//       </li>
//       <li>
//         <p>Solicitar la portabilidad de los datos facilitados por el Usuario en aquellos casos previstos en la normativa</p>
//       </li>
//       <li>
//         <p>Ponerse en contacto con el DPO de <strong>GRUPO EDELVIVES</strong> a través de la siguiente dirección: <a target="_blank" href="mailto:dpo@edelvives.es">dpo@edelvives.es</a></p>
//       </li>
//       <li>
//         <p>Interponer una reclamación relativa a la protección de sus datos personales ante la Agencia Española de
//           Protección de Datos en la dirección Calle de Jorge Juan, 6, 28001 Madrid, cuando el interesado considere que
//           <strong>GRUPOEDELVIVES</strong>
//           ha vulnerado los derechos que le son reconocidos por la normativa aplicable en protección de datos.</p>
//       </li>
//     </ul>
//   </li>
//   <li>
//     <h3>MEDIDAS DE SEGURIDAD.</h3>
//     <p><strong>GRUPO EDELVIVES</strong> tratará los datos del usuario, en todo momento, de forma absolutamente
//       confidencial y guardando el preceptivo deber de secreto respecto de los mismos, de conformidad con lo previsto en
//       la normativa de aplicación, adoptando al efecto las medidas de índole técnica y organizativas necesarias que
//       garanticen la seguridad de sus datos y eviten su alteración, pérdida, tratamiento o acceso no autorizado, habida
//       cuenta del estado de la tecnología, la naturaleza de los datos almacenados y los riesgos a que están expuestos.</p>
//     <p>Última actualización: 21 de enero de 2019.</p>
//   </li>
// </ol>
// </div>
// <div class="ccokies">
// <h3><a name="punto3a"></a>POLÍTICA DE COOKIES</h3>
// <p>Esta Política de Cookies es parte integrante del Aviso Legal y la Política de Privacidad de la página web <a target="_blank" href=${
//     isEdelvivesMX() ? 'https://edelvivesdigitalplus.com.mx/' : 'https://edelvivesdigitalplus.com/'
//   }>${isEdelvivesMX() ? 'https://edelvivesdigitalplus.com.mx/' : 'https://edelvivesdigitalplus.com/'}</a> (en adelante, el <strong>“Sitio
//     web”</strong>).El acceso y la navegación en el Sitio Web, o el uso de los servicios del mismo, implican la
//   aceptación de los términos y condiciones recogido en el Aviso Legal y en la Política de Privacidad.</p>
// <p>Con el fin de facilitar la navegación por el Sitio Web, <strong>GRUPO EDITORIAL LUIS VIVES</strong>, como titular
//   del mismo (en adelante, <strong>“GRUPO EDELVIVES”</strong>) con domicilio social en Ctra. de Madrid km. 315,700,
//   50.012, Zaragoza, con N.I.F. R 5000274 J, le comunica que utiliza cookies u otros dispositivos de almacenamiento de
//   almacenamiento y recuperación de datos de funcionalidad similar (en adelante, las <strong>“Cookies”</strong>).</p>
// <p>En este sentido y con el objetivo de garantizar al usuario toda la información necesaria para su correcta
//   navegación, ponemos a disposición del usuario el siguiente texto informativo sobre qué son las cookies, qué
//   tipología de cookies existen en nuestra Página Web y cómo es posible configurarlas o deshabilitarlas.</p>
// <ol>
//   <li>
//     <p>¿Qué es una Cookie?</p>
//     <p>Las Cookies son archivos que contienen pequeñas cantidades de información que se descargan en el dispositivo del
//       usuario cuando visita una página web. Su finalidad principal es reconocer al usuario cada vez que accede al Sitio
//       Web permitiendo, además, mejorar la calidad y ofrecer un mejor uso del Sitio Web.</p>
//     <p>Las Cookies son esenciales para el funcionamiento de Internet, ya que ayudan, entre otras funciones, a
//       identificar y resolver posibles errores de funcionamiento del Sitio Web.</p>
//   </li>
//   <li>
//     <p>Tipología de cookies</p>
//     <p>En el presente Sitio Web se utilizan cookies propias y de terceros. Las cookies utilizadas se almacenan en su
//       ordenador durante un tiempo determinado, éstas pueden considerarse cookies de sesión (si caducan cuando abandona
//       nuestro Sitio Web) o persistentes (si la caducidad de las cookies dura más tiempo). Las cookies utilizadas en la
//       presente página Web son:</p>
//     <p><strong>Cookies “estrictamente necesarias”</strong> Son aquellas cookies consideradas imprescindibles para la
//       navegación por la Página Web, pues facilitan al usuario la utilización de sus prestaciones o herramientas como,
//       por ejemplo, identificar la sesión, acceder a partes de acceso restringido, recordar los elementos que integran un
//       pedido, realizar el proceso de compra de un pedido, entre otros. Sin estas cookies, las funciones mencionadas
//       quedarían inhabilitadas.</p>
//     <p><strong>Cookies sobre comportamiento</strong>
//       </p><p>Son aquellas cookies cuyo objetivo es la recopilación de información relativa a los usos de navegación del
//         usuario, como por ejemplo las páginas que son más visitadas, los mensajes de error, entre otros, con el objetivo
//         por parte del responsable, de introducir mejoras en la página web en base a la información recopilada por estas
//         cookies.</p>
//       <p>La información recopilada por estas cookies es anónima no pudiendo identificar al usuario personalmente y, por
//         lo tanto, se utiliza exclusivamente para el correcto funcionamiento de la página web.</p>
//     <p></p>
//     <p><strong>Cookies de funcionalidad</strong>
//       </p><p>Son aquellas cookies que permiten recordar a la página web las decisiones que el usuario toma, por ejemplo: se
//         almacenará la ubicación geográfica del usuario para asegurar que se muestra el sitio web indicado para su región,
//         el nombre de usuario, el idioma, el tipo de navegador mediante el cual se accede a la página web, entre otros. El
//         objetivo es la prestación de un servicio más personalizado.</p>
//       <p>La información recopilada por estas cookies es anónima no pudiendo identificar al usuario personalmente y, por
//         lo tanto, se utiliza exclusivamente para el funcionamiento de la página web.</p>
//     <p></p>
//     <p><strong>Cookies de personalización y de publicidad</strong>
//       </p><p>Son aquellas cookies que se utilizan por los operadores publicitarios con el permiso del titular de la página
//         web, las cuales recogen información sobre las preferencias y elecciones del usuario en su navegación por sitios
//         web. Se dirigen a las redes de publicidad quienes las utilizan para luego mostrar al usuario anuncios
//         personalizados en otros sitios web.</p>
//     <p></p>
//   </li>
//   <li>
//     <p>Cuadro resumen de cookies utilizadas en el Sitio Web</p>
//     <p><strong>GRUPO EDELVIVES</strong> utiliza cookies para personalizar la navegación del usuario por los Sitios Web
//       de su titularidad, cookies de entrada de usuario y cookies de sesión para equilibrar la carga, las cuales están
//       excluidas del ámbito de aplicación del artículo 22.2 de la LSSI.</p>
//     <p>Por otra parte, <strong>GRUPO EDELVIVES</strong> utiliza cookies de analítica web para medir y analizar la
//       navegación de los usuarios en los Sitios Web. <strong>GRUPO EDELVIVES</strong> utiliza los datos obtenidos con el
//       fin de introducir mejoras en función del análisis de los datos de uso que hacen los usuarios. La analítica web no
//       permite obtener información sobre el nombre, apellidos o dirección de correo electrónico o postal del usuario. La
//       información obtenida es la relativa al número de usuarios que acceden a la web, el número de páginas vistas, la
//       frecuencia y repetición de las visitas, su duración, el navegador utilizado, el operador que presta el servicio,
//       el idioma, el terminal utilizado o la ciudad a la que está asignada la dirección IP.</p>
//     <p>Del mismo modo, <strong>GRUPO EDELVIVES</strong> utiliza cookies de publicidad comportamental para la gestión de
//       los espacios publicitarios en base a criterios determinados y cookies de complemento (plug-in) para intercambiar
//       contenidos sociales. Estas cookies almacenan información del comportamiento de los usuarios obtenida a través de
//       la observación continuada de sus hábitos de navegación, lo que permite mostrar publicidad en función del mismo.</p>
//     <table>
//       <thead>
//         <tr>
//           <td>NOMBRE</td>
//           <td>TITULAR</td>
//           <td>FINALIDAD</td>
//           <td>DURACIÓN</td>
//         </tr>
//       </thead>
//       <tbody>
//         <tr>
//           <td><strong>_ga</strong></td>
//           <td>Google</td>
//           <td>Esta cookie de origen contiene un identificador anónimo usado para distinguir usuarios.</td>
//           <td>2 años o actualización</td>
//         </tr>
//         <tr>
//           <td><strong>_gat</strong></td>
//           <td>Google</td>
//           <td>Esta cookie de origen contiene un identificador para diferenciar entre los diferentes objetos de seguimiento
//             creados en la sesión.</td>
//           <td>10 minutos desde creación o modificación</td>
//         </tr>
//         <tr>
//           <td><strong>_gid</strong></td>
//           <td>Google</td>
//           <td>Esta cookie se usa para distinguir usuarios.</td>
//           <td>24 horas</td>
//         </tr>
//         <tr>
//           <td><strong>NID</strong></td>
//           <td>Google</td>
//           <td></td>
//           <td></td>
//         </tr>
//         <tr>
//           <td><strong>G_ENABLED_IDPS</strong></td>
//           <td></td>
//           <td></td>
//           <td></td>
//         </tr>
//         <tr>
//           <td><strong>ci_session_i2c</strong></td>
//           <td></td>
//           <td></td>
//           <td></td>
//         </tr>
//         <tr>
//           <td><strong>SIDCC</strong></td>
//           <td>Google</td>
//           <td></td>
//           <td></td>
//         </tr>
//         <tr>
//           <td><strong>OCAK</strong></td>
//           <td></td>
//           <td></td>
//           <td></td>
//         </tr>
//         <tr>
//           <td><strong>GAPS</strong></td>
//           <td></td>
//           <td></td>
//           <td></td>
//         </tr>
//         <tr>
//           <td><strong>CheckConnectionTempCookie529</strong></td>
//           <td></td>
//           <td></td>
//           <td></td>
//         </tr>
//         <tr>
//           <td><strong>clusterBAK</strong></td>
//           <td></td>
//           <td></td>
//           <td></td>
//         </tr>
//         <tr>
//           <td><strong>clusterD</strong></td>
//           <td></td>
//           <td></td>
//           <td></td>
//         </tr>
//         <tr>
//           <td><strong>edelvives</strong></td>
//           <td></td>
//           <td></td>
//           <td></td>
//         </tr>
//         <tr>
//           <td><strong>PrestaShop-b78d98b21a70688c13d5c9c7f255a804</strong></td>
//           <td></td>
//           <td></td>
//           <td></td>
//         </tr>
//         <tr>
//           <td><strong>PHPSESSID</strong></td>
//           <td></td>
//           <td></td>
//           <td></td>
//         </tr>
//         <tr>
//           <td><strong>endprom</strong></td>
//           <td></td>
//           <td></td>
//           <td></td>
//         </tr>
//       </tbody>
//     </table>
//   </li>
//   <li>
//     <p>Configuración del usuario para evitar Cookies</p>
//     <p>En cumplimiento de la normativa legal vigente, <strong>GRUPO EDELVIVES</strong> ppone a disposición de los
//       usuarios del Sitio Web la información que les permita configurar su navegador/navegadores de Internet para
//       mantener su privacidad y seguridad en relación con las Cookies. Por ello, se facilita la información y los enlaces
//       a los sitios de soporte oficiales de los principales navegadores para que cada usuario pueda decidir si desea o no
//       aceptar el uso de Cookies.</p>
//     <p>Así, es posible bloquear las Cookies a través de las herramientas de configuración del navegador, o bien, cada
//       usuario puede configurar su navegador para que le avise cuando un servidor quiera guardar una Cookie:</p>
//     <ol style="list-style-type: lower-alpha;">
//       <li>
//         <p>Si utiliza Microsoft Internet Explorer, encontrará la opción en el menú Herramientas &gt; Opciones de Internet &gt;
//           Privacidad &gt; Configuración. Para saber más visite <a target="_blank" href="http://windows.microsoft.com/es-es/windows-vista/block-or-allow-Cookies">http://windows.microsoft.com/es-es/windows-vista/block-or-allow-Cookies</a>
//           y <a target="_blank" href="http://windows.microsoft.com/es-es/windows7/how-to-manage-Cookies-in-internet-explorer-9">http://windows.microsoft.com/es-es/windows7/how-to-manage-Cookies-in-internet-explorer-9</a>.</p>
//       </li>
//       <li>
//         <p>Si utiliza Firefox, encontrará la opción en el menú Herramientas &gt; Opciones &gt; Privacidad &gt; Cookies. Para
//           saber más visite <a target="_blank" href="http://support.mozilla.org/es/kb/habilitar-y-deshabilitar-Cookies-que-los-sitios-we">http://support.mozilla.org/es/kb/habilitar-y-deshabilitar-Cookies-que-los-sitios-we</a>.</p>
//       </li>
//       <li>
//         <p>Si utiliza Chrome, en la sección de Opciones &gt; Opciones avanzadas &gt; Privacidad. Para saber más <a target="_blank" href="https://support.google.com/accounts/answer/61416?hl=es">https://support.google.com/accounts/answer/61416?hl=es</a></p>
//       </li>
//       <li>
//         <p>Si utiliza Opera, en la opción de Seguridad y Privacidad, podrá configurar el navegador. Para saber más visite
//           <a target="_blank" href="http://help.opera.com/Windows/11.50/es-ES/cookies.html">http://help.opera.com/Windows/11.50/es-ES/cookies.html</a></p>
//       </li>
//       <li>
//         <p>Si utiliza Safari encontrará la opción en el menú Preferencias/Privacidad. Más información en: <a target="_blank" href="http://support.apple.com/kb/HT1677?viewlocale=es_ES">http://support.apple.com/kb/HT1677?viewlocale=es_ES</a></p>
//       </li>
//     </ol>
//   </li>
// </ol>
// <p>Última actualización: 21 de enero de 2019.</p></div></div>`,
//   vl: `<div id="tyc"><div class="tyc_legal"><h3 id="privacy-policy"><a name="punto3a"></a>POLÍTICA DE PRIVACITAT </h3>
// <ol>
//   <li>
//     <h3>IDENTIFICACIÓ</h3>
//     <ul>
//       <li>Identitat: <strong>GRUPO  EDITORIAL LUIS VIVES</strong> VIVES (d'ara en avant, «GRUPO EDELVIVES»)</li>
//       <li>Domicilio: Carretera de Madrid, Km 315,700, 50001-Zaragoza</li>
//       <li>NIF: R5000274J</li>
//       <li>Delegat de Protecció de Dades (DPO): pot contactar amb el nostre DPO a través dels següents mitjans:
//         - E-mail: dpo@edelvives.es
//         - Adreça: Carrer  Xaudaró, 25, 28034 Madrid. . Indique en la referència «Delegat de Protecció de Dades». </li>
//     </ul>
//   </li>
//   <li>
//     <h3>INFORMACIÓ I CONSENTIMENT.</h3>
//     <p>Mitjançant l'acceptació de la present Política de Privacitat, l'usuari queda informat i presta el seu consentiment lliure, informat, específic i inequívoc perquè les dades personals que facilite a través de la pàgina web situada en l'URL <a target="_blank" href="https://edelvivesdigitalplus.com/">https://edelvivesdigitalplus.com/</a>(d'ara en avant, el «Lloc web») siguen trarctades per GRUPO EDELVIVES, així com les dades derivades de la navegació i aquelles altres dades que puga facilitar en un futur a GRUPO EDELVIVES. </p>
//     <p>L'usuari ha de llegir amb atenció aquesta Política de Privacitat, que s’ha redactat de manera clara i senzilla, per a facilitar-ne la comprensió, i ha de determinar lliurement i voluntàriament si desitja facilitar les seues dades personals a GRUPO EDELVIVES. Els MENORS D'EDAT, només poden aportar dades personals amb el previ consentiment de pares o tutors, que s’ha d’enviar, degudament signat i amb còpia del DNI del pare, mare o tutor signant, a l'adreça rgpd@edelvives.es GRUPO EDELVIVES no respon de les dades de menors que, sense poder conéixer aquest fet, s'hagen facilitat sense consentiment de pare, mare o tutor.</p>
//   </li>
//   <li>
//     <h3>OBLIGATORIETAT DE FACILITAR LES DADES. </h3>
//     <p>Les dades sol·licitades en els formularis del Lloc web són, amb caràcter general, obligatòries (llevat que en el camp requerit s'especifique el contrari) per a complir amb les finalitats establides. Aquestes finalitats no podran atendre’s si no es faciliten les dades o no es faciliten, sense perjudici que l’usuari puga visualitzar lliurement el contingut del Lloc web. </p>
//   </li>
//   <li>
//     <h3>AMB QUINA FINALITAT TRACTARÀ <strong>GRUPO  EDELVIVES</strong> LES DADES PERSONALS DE L'USUARI? </h3>
//     <p>Les dades personals facilitades a través del Lloc web seran tractades per GRUPO EDELVIVES conforme a les següents finalitats:</p>
//     <ol>
//       <li>Dades facilitades per al registre en el Portal d'usuari:
//         <ul>
//           <li>
//             <p>Gestionar el registre com a usuari en la plataforma en línia propietat de <strong>GRUPO EDELVIVES</strong> i allotjada en el present Lloc web.</p>
//           </li>
//           <li>
//             <p>Gestionar el contingut del Portal.</p>
//           </li>
//           <li>
//             <p>Efectuar anàlisis sobre la utilització del Lloc web i comprovar les preferències i comportament dels usuaris.</p>
//           </li>
//         </ul>
//       </li>
//       <li>
//         <p>Dades facilitades en els formularis de contacte i altres canals posats a la disposició dels usuaris:</p>
//         <ul>
//           <li>
//             <p>Gestionar les sol·licituds de contacte i informació de l'usuari a través dels canals disposats per a això en els llocs web de GRUPO EDELVIVES.</p>
//           </li>
//           <li>
//             <p>Gestió de la petició plantejada.</p>
//           </li>
//           <li>
//             <p>Efectuar anàlisis sobre la utilització del Lloc web i comprovar les preferències i comportament dels usuaris. </p>
//           </li>
//         </ul>
//       </li>
//     </ol>
//   </li>
//   <li>
//     <h3>QUINES DADES DE L'USUARI TRACTARÀ GRUPO  EDELVIVES?</h3>
//     <p>GRUPO EDELVIVES tractarà les següents categories de dades de l'usuari:</p>
//     <ol>
//       <li>
//         <p>Dades facilitades per al registre en el <strong><u>Portal d'usuari </u></strong></p>
//         <ul>
//           <li>
//             <p>Dades identificatives: nom, cognoms.</p>
//           </li>
//           <li>
//             <p>Dades de contacte: domicili, adreça de correu electrònic, número de telèfon.</p>
//           </li>
//           <li>
//             <p>Altres dades: dades facilitades pels interessats en els camps oberts.</p>
//           </li>
//           <li>
//             <p>Dades de navegació.</p>
//           </li>
//         </ul>
//       </li>
//       <li>
//         <p>Dades facilitades en els <strong><u>formularis de contacte i altres canals posats a la disposició dels usuaris</u></strong>:</p>
//         <ul>
//           <li>
//             <p>Dades identificatives: nom, cognoms.</p>
//           </li>
//           <li>
//             <p>Dades de contacte: adreça de correu electrònic, número de telèfon.</p>
//           </li>
//           <li>
//             <p>Dades de transacció de béns i serveis.</p>
//           </li>
//           <li>
//             <p>Altres dades: dades facilitades pels interessats en els camps oberts dels formularis disposats en el Lloc web o en els documents adjunts.</p>
//           </li>
//           <li>
//             <p>Dades de navegació. </p>
//           </li>
//         </ul>
//         <p>En cas que l'usuari facilite dades de tercers, manifesta comptar amb el consentiment dels mateixos i es compromet a traslladar-li la informació continguda en la Política de Privacitat. Eximeix així <strong>GRUPO EDELVIVES</strong> de qualsevol responsabilitat en aquest sentit. No obstant això, <strong>GRUPO EDELVIVES</strong> podrà dur a terme les verificacions periòdiques per a constatar aquest fet i adoptar les mesures de diligència deguda que correspongue conforme a la normativa de protecció de dades.</p>
//       </li>
//     </ol>
//   </li>
//   <li>
//     <h3>QUINA ÉS LA LEGITIMACIÓ DEL TRACTAMENT DE LES DADES DE L'USUARI?</h3>
//     <p>La legitimació del tractament de les seues dades personals, seran les següents:</p>
//     <ol>
//       <li>
//         <p>Dades facilitades per al registre en el Portal d'usuari: el consentiment que se li sol·licita i que pot retirar en qualsevol moment. No obstant això, en cas de retirar el seu consentiment, això no afectarà la licitud dels tractaments efectuats amb anterioritat. Per a l'execució d'anàlisis sobre la utilització de la web, l'interés legítim GRUPO EDELVIVES.</p>
//       </li>
//       <li>
//         <p>Per als formularis de contacte i altres canals posats a la disposició dels usuaris: el consentiment que se li sol·licita i que pot retirar en qualsevol moment. No obstant això, en cas de retirar el seu consentiment, això no afectarà la licitud dels tractaments efectuats amb anterioritat. Per a l'execució d'anàlisis sobre la utilització de la web, l'interés legítim de GRUPO  EDELVIVES.</p>
//       </li>
//       <li>
//       <p>Per a la gestió del seu usuari i les incidències tècniques que hipotèticament pogueren produir-se en la Plataforma, GRUPO EDELVIVES es troba legitimat legalment per al tractament dels dades personals dels usuaris d'acord amb l'execució el corresponent contracte de prestació dels serveis, tal com s'indica a l'Avís Legal i a la present Política de Privadesa. </p>
//     </li>
//     </ol>
//     <p>Els consentiments obtinguts per a les finalitats esmentades són independents; l'usuari podrà revocar només un d'ells sense afectar els altres.</p>
//     <p>Per a revocar aquest consentiment, l'Usuari podrà contactar amb GRUPO  EDELVIVES, en qualsevol moment, a través dels canals següents: <a target="_blank" href="mailto:rgpd@edelvives.es">rgpd@edelvives.es</a>,
//     indicant en l'assumpte de referència «Dades Personals».</p>
//   </li>
//   <li>
//     <h3>AMB QUINS DESTINATARIS ES COMPARTIRAN LES DADES DE L'USUARI?</h3>
//     <p>Les dades de l'usuari podran ser comunicar-se a:</p>
//     <ol>
//       <li>
//         <p>Dades facilitades per al registre en el Portal d'usuari: Empreses del grup al qual pertany GRUPO  EDELVIVES, únicament per a fins administratius interns i/o per a les finalitats anteriorment indicades.</p>
//       </li>
//       <li>
//         <p>Dades facilitades per als formularis de contacte i altres canals posats a la disposició dels usuaris: Empreses del grup al qual pertany GRUPO EDELVIVES, únicament per a fins administratius interns i/o per a les finalitats anteriorment indicades.</p>
//       </li>
//     </ol>
//     <p>Addicionalment, les dades podran ser accessibles per proveïdors de GRUPO  EDELVIVES. Aquest accés serà el necessari per a l'adequat compliment de les obligacions legals i/o de les finalitats anteriorment indicades. Aquests proveïdors no tractaran les seues dades per a finalitats pròpies que no hagen sigut prèviament informades per GRUPO EDELVIVES.</p>
//   </li>
//   <li>
//     <h3>CONSERVACIÓ DE LES DADES</h3>
//     <p>Les seues dades seran conservades durant els terminis següents:</p>
//     <ol>
//       <li>
//         <p>Dades facilitades per al registre en el Portal d'usuari: seran conservades fins que l'usuari sol·licite la baixa i, una vegada sol·licitada, durant el termini de prescripció d'accions legals derivades de l'ús d'aquesta.</p>
//       </li>
//       <li>
//         <p>Dades facilitades per als formularis de contacte i altres canals posats a la disposició dels usuaris: seran conservades durant el termini necessari per a donar tràmit i contestació a la sol·licitud i, una vegada finalitzat, durant el termini de prescripció de les accions legals derivades de la citada sol·licitud.</p>
//       </li>
//     </ol>
//   </li>
//   <li>
//     <h3>RESPONSABILITAT DE L'USUARI.</h3>
//     <p>L'usuari:</p>
//     <ul>
//       <li>
//         <p>Garanteix que és major de díhuit (18) anys i que les dades que facilita a <strong>GRUPO  EDELVIVES</strong> són vertaderes, exactes, completes i actualitzades. A aquest efecte, l'usuari respon de la veracitat de totes les dades que comunique i mantindrà convenientment actualitzada la informació facilitada, de tal forma que responga a la seua situació real.</p>
//       </li>
//       <li>
//         <p>Garanteix que ha informat els tercers dels quals en facilite les dades, en cas de fer-ho, dels aspectes continguts en aquest document. Així mateix, garanteix que ha obtingut l’autorització per a facilitar aquestese dades a <strong>GRUPO EDELVIVES</strong> per als fins assenyalats.</p>
//       </li>
//       <li>
//         <p>Serà responsable de les informacions falses o inexactes que proporcione a través del Lloc web i dels danys i perjudicis, directes o indirectes, que això cause a <strong>GRUPO EDELVIVES</strong> o a tercers.</p>
//       </li>
//     </ul>
//   </li>
//   <li>
//     <h3>EXERCICI DE DRETS.</h3>
//     <p>L'usuari pot enviar un escrit a GRUPO EDELVIVES, a l'adreça indicada en l'encapçalament de la present Política, o bé per mitjà d'un correu electrònic a l'adreça <a target="_blank" href="mailto:rgpd@edelvives.es">rgpd@edelvives.es</a>,
//     adjuntant fotocòpia del document d'identitat, en qualsevol moment i de manera gratuïta, per a:</p>
//     <ul>
//       <li>
//         <p>Revocar els consentiments atorgats.</p>
//       </li>
//       <li>
//         <p>Obtindre confirmació sobre si en <strong>GRUPO EDELVIVES</strong> s'estan tractant dades personals que concerneixen l'usuari o no.</p>
//       </li>
//       <li>
//         <p>Accedir a les seues dades personals.</p>
//       </li>
//       <li>
//         <p>Rectificar les dades inexactes o incompletes.</p>
//       </li>
//       <li>
//         <p>Sol·licitar la supressió de les seues dades quan, entre altres motius, les dades ja no siguen necessàries per als fins pels quals van ser recollides.</p>
//       </li>
//       <li>
//         <p>Obtindre de <strong>GRUPO EDELVIVES</strong> la limitació del tractament de les dades quan es complisca alguna de les condicions previstes en la normativa de protecció de dades.</p>
//       </li>
//       <li>
//         <p>Sol·licitar la portabilitat de les dades facilitades per l'Usuari en aquells casos previstos en la normativa</p>
//       </li>
//       <li>
//         <p>Posar-se en contacte amb el DPO de <strong>GRUPO EDELVIVES</strong> a través de la següent adreça: <a target="_blank" href="mailto:dpo@edelvives.es">dpo@edelvives.es</a></p>
//       </li>
//       <li>
//         <p>Interposar una reclamació relativa a la protecció de les seues dades personals davant l'Agència Espanyola de Protecció de Dades en l'adreça Carrer de Jorge Juan, 6, 28001 Madrid, quan l'interessat considere que <strong>GRUPO EDELVIVES</strong> ha vulnerat els drets que li són reconeguts per la normativa aplicable en protecció de dades.</p>
//       </li>
//     </ul>
//   </li>
//   <li>
//     <h3>MESURES DE SEGURETAT.</h3>
//     <p>GRUPO EDELVIVES tractarà les dades de l'usuari, en tot moment, de forma absolutament confidencial i guardant el preceptiu deure de secret respecte d'aquestes, de conformitat amb el que es preveu en la normativa d'aplicació. Adoptarà a aquest efecte les mesures d'índole tècnica i organitzativa necessàries per a garantir la seguretat de les seues dades i evitar-ne l’alteració, pèrdua, tractament o accés no autoritzat, tenint en compte l'estat de la tecnologia, la naturalesa de les dades emmagatzemades i dels riscos als quals s’exposen. </p>
//     <p>Última actualització: 21 de gener de 2019. </p>
//   </li>
// </ol>
// </div>
// <div class="ccokies">
//     <h3><a name="punto3a"></a>POLÍTICA DE COOKIES</h3>
//     <p>Aquesta Política de Cookies és part integrant de l'Avís Legal i la Política de Privacitat de la pàgina web https://edelvivesdigitalplus.com/ (d'ara en avant, el «Lloc web»). L'accés i la navegació en el Lloc web, o l'ús dels serveis d'aquest, impliquen l'acceptació dels termes i condicions recollits en l'Avís Legal i en la Política de Privacitat.
//     Amb l’objectiu de facilitar la navegació pel Lloc web, GRUPO  EDITORIAL LUIS VIVES, com a titular del mateix (d'ara en avant, «GRUPO  EDELVIVES») amb domicili social en Ctra. de Madrid km. 315,700, 50.012, Saragossa, amb NIF R 5000274 J, li comunica que utilitza cookies o altres dispositius d'emmagatzematge i recuperació de dades de funcionalitat similar (d'ara en avant, les «cookies»). En aquest sentit, i amb l'objectiu de garantir a l'usuari tota la informació necessària per a la correcta navegació, posem a la disposició de l'usuari el següent text informatiu sobre què són les cookies, quina tipologia de cookiies existeixen en la nostra Pàgina Web i com és possible configurar-les o deshabilitar-les.
//     </p>
//     <ol>
//       <li>
//         <p>Què és una cookie?</p>
//         <p> Les cookies són arxius que contenen xicotetes quantitats d'informació que es descarreguen en el dispositiu de l'usuari quan visita una pàgina web. La seua finalitat principal és reconéixer l'usuari cada vegada que accedeix al Lloc web per a millorar la qualitat i oferir un millor ús del Lloc web. Les cookies són essencials per al funcionament d'internet, ja que ajuden, entre altres funcions, a identificar i resoldre possibles errors de funcionament del Lloc web. </p>
//         <p>Las Cookies son esenciales para el funcionamiento de Internet, ya que ayudan, entre otras funciones, a
//           identificar y resolver posibles errores de funcionamiento del Sitio Web.</p>
//       </li>
//       <li>
//         <p>Tipologia de cookies</p>
//         <p>En el present Lloc web s'utilitzen cookies pròpies i de tercers. Les cookies utilitzades s'emmagatzemen en el seu ordinador durant un temps determinat, aquestes poden considerar-se cookies de sessió (si caduquen quan abandona el nostre Lloc web) o persistents (si la caducitat de les cookies dura més temps). Les cookies utilitzades en la present pàgina Web són: </p>
//         <p><strong>Cookies “estrictament necessàries”</strong> Són aquelles cookies considerades imprescindibles per a la navegació per la Pàgina Web, perquè faciliten a l'usuari la utilització de les seues prestacions o eines com, per exemple, identificar la sessió, accedir a parts d'accés restringit, recordar els elements que integren una comanda, realitzar el procés de compra d'una comanda, entre d’altres. Sense aquestes cookies, les funcions esmentades quedarien inhabilitades. </p>
//         <p><strong>Cookies sobre comportament </strong>
//           </p><p>Són aquelles cookies l'objectiu de les quals és recopilar informació relativa als usos de navegació de l'usuari, com per exemple les pàgines que són més visitades o els missatges d'error, entre d’altres, amb l'objectiu per part del responsable, d'introduir millores en la pàgina web a partir de la informació recopilada per aquestes cookies.</p>
//           <p>La informació recopilada per aquestes cookies és anònima, no permet identificar l'usuari personalment i, per tant, s'utilitza exclusivament per al correcte funcionament de la pàgina web. </p>
//         <p></p>
//         <p><strong>Cookies de funcionalitat </strong>
//           </p><p>Són aquelles cookies que permeten recordar a la pàgina web les decisions que l'usuari pren, per exemple: s'emmagatzemarà la ubicació geogràfica de l'usuari per a assegurar que es mostra el lloc web indicat per a la seua regió, el nom d'usuari, l'idioma o el tipus de navegador mitjançant el qual s'accedeix a la pàgina web, entre d’altres. L'objectiu és la prestació d'un servei més personalitzat. La informació recopilada per aquestes cookies és anònima, no permet identificar l'usuari personalment i, per tant, s'utilitza exclusivament per al funcionament de la pàgina web. </p>
//         <p></p>
//         <p><strong>Cookies de personalització i de publicitat </strong>
//           </p><p>Són aquelles cookies que utilitzen els operadors publicitaris amb el permís del titular de la pàgina web. Recullen informació sobre les preferències i eleccions de l'usuari en la seua navegació per llocs web i es dirigeixen a les xarxes de publicitat, que les utilitzen per a després mostrar a l'usuari anuncis personalitzats en altres llocs web.</p>
//         <p></p>
//       </li>
//       <li>
//         <p>Quadre resum de cookies utilitzades en el Lloc web</p>
//         <p>GRUPO  EDELVIVES utilitza cookies per a personalitzar la navegació de l'usuari pels Llocs web de la seua titularitat, cookies d'entrada d'usuari i cookies de sessió per a equilibrar la càrrega, les quals estan excloses de l'àmbit d'aplicació de l'article 22.2 de la LSSI. </p>
//         <p>D'altra banda,<strong>GRUPO  EDELVIVES</strong> utilitza cookies d'analítica web per a mesurar i analitzar la navegació dels usuaris en els Llocs web.<strong>GRUPO  EDELVIVES</strong> utilitza les dades obtingudes amb la finalitat d'introduir millores en funció de l'anàlisi de les dades d'ús que fan els usuaris. L'analítica web no permet obtindre informació sobre el nom, cognoms o adreça de correu electrònic o postal de l'usuari. La informació obtinguda és la relativa al nombre d'usuaris que accedeixen a la web, el nombre de pàgines vistes, la freqüència i repetició de les visites, la duració, el navegador utilitzat, l'operador que presta el servei, l'idioma, el terminal utilitzat o la ciutat a la qual està assignada l'adreça IP. De la mateixa manera,<strong>GRUPO  EDELVIVES</strong> utilitza cookies de publicitat comportamental per a gestionar els espais publicitaris sobre la base de criteris determinats i cookies de complement (plug-in) per a intercanviar continguts socials. Aquestes cookies emmagatzemen informació del comportament dels usuaris obtinguda a través de l'observació continuada dels seus hàbits de navegació, la qual cosa permet mostrar publicitat en funció d'aquest.</p>
//         <table>
//           <thead>
//             <tr>
//               <td>NOMBRE</td>
//               <td>TITULAR</td>
//               <td>FINALIDAD</td>
//               <td>DURACIÓN</td>
//             </tr>
//           </thead>
//           <tbody>
//             <tr>
//               <td><strong>_ga</strong></td>
//               <td>Google</td>
//               <td>Aquesta cookie d'origen conté un identificador anònim usat per a distingir usuaris</td>
//               <td>2 años o actualización</td>
//             </tr>
//             <tr>
//               <td><strong>_gat</strong></td>
//               <td>Google</td>
//               <td>Aquesta cookie d'origen conté un identificador per a diferenciar entre els objectes de seguiment creats en la sessió.</td>
//               <td>10 minuts des de creació o modificació</td>
//             </tr>
//             <tr>
//               <td><strong>_gid</strong></td>
//               <td>Google</td>
//               <td>Aquesta cookie s'usa per a distingir usuaris.</td>
//               <td>24 hores</td>
//             </tr>
//             <tr>
//               <td><strong>NID</strong></td>
//               <td>Google</td>
//               <td></td>
//               <td></td>
//             </tr>
//             <tr>
//               <td><strong>G_ENABLED_IDPS</strong></td>
//               <td></td>
//               <td></td>
//               <td></td>
//             </tr>
//             <tr>
//               <td><strong>ci_session_i2c</strong></td>
//               <td></td>
//               <td></td>
//               <td></td>
//             </tr>
//             <tr>
//               <td><strong>SIDCC</strong></td>
//               <td>Google</td>
//               <td></td>
//               <td></td>
//             </tr>
//             <tr>
//               <td><strong>OCAK</strong></td>
//               <td></td>
//               <td></td>
//               <td></td>
//             </tr>
//             <tr>
//               <td><strong>GAPS</strong></td>
//               <td></td>
//               <td></td>
//               <td></td>
//             </tr>
//             <tr>
//               <td><strong>CheckConnectionTempCookie529</strong></td>
//               <td></td>
//               <td></td>
//               <td></td>
//             </tr>
//             <tr>
//               <td><strong>clusterBAK</strong></td>
//               <td></td>
//               <td></td>
//               <td></td>
//             </tr>
//             <tr>
//               <td><strong>clusterD</strong></td>
//               <td></td>
//               <td></td>
//               <td></td>
//             </tr>
//             <tr>
//               <td><strong>edelvives</strong></td>
//               <td></td>
//               <td></td>
//               <td></td>
//             </tr>
//             <tr>
//               <td><strong>PrestaShop-b78d98b21a70688c13d5c9c7f255a804</strong></td>
//               <td></td>
//               <td></td>
//               <td></td>
//             </tr>
//             <tr>
//               <td><strong>PHPSESSID</strong></td>
//               <td></td>
//               <td></td>
//               <td></td>
//             </tr>
//             <tr>
//               <td><strong>endprom</strong></td>
//               <td></td>
//               <td></td>
//               <td></td>
//             </tr>
//           </tbody>
//         </table>
//       </li>
//       <li>
//         <p>Configuració de l'usuari per a evitar cookies </p>
//         <p>En compliment de la normativa legal vigent,<strong>GRUPO  EDELVIVES</strong> posa a disposició dels usuaris del Lloc web la informació que els permeta configurar el seu navegador/navegadors d'internet per a mantindre la seua privacitat i seguretat en relació amb les cookies. Per això, es facilita la informació i els enllaços als llocs de suport oficials dels principals navegadors perquè cada usuari puga decidir si vol o no acceptar l'ús de cookies. </p>
//         <p>Així, és possible bloquejar lescookies a través de les eines de configuració del navegador, o bé, cada usuari pot configurar el seu navegador perquè l’avise quan un servidor vulga guardar una cookie: </p>
//         <ol style="list-style-type: lower-alpha;">
//           <li>
//             <p>Si utilitza Microsoft Internet Explorer, trobarà l'opció en el menú Eines > Opcions d'Internet > Privadesa > Configuració. Per a saber-ne més, visite <a target="_blank" href="http://windows.microsoft.com/es-es/windows-vista/block-or-allow-Cookies">http://windows.microsoft.com/es-es/windows-vista/block-or-allow-Cookies</a>
//               i <a target="_blank" href="http://windows.microsoft.com/es-es/windows7/how-to-manage-Cookies-in-internet-explorer-9">http://windows.microsoft.com/es-es/windows7/how-to-manage-Cookies-in-internet-explorer-9</a>.</p>
//           </li>
//           <li>
//             <p>Si utilitza Firefox, trobarà l'opció en el menú Eines > Opcions > Privadesa > Cookies. Per a saber-ne més, visite <a target="_blank" href="http://support.mozilla.org/es/kb/habilitar-y-deshabilitar-Cookies-que-los-sitios-we">http://support.mozilla.org/es/kb/habilitar-y-deshabilitar-Cookies-que-los-sitios-we</a>.</p>
//           </li>
//           <li>
//             <p>Si utilitza Chrome, en la secció d'Opcions > Opcions avançades > Privadesa. Per a saber-ne més, visite https://support.google.com/accounts/answer/61416?hl=es <a target="_blank" href="https://support.google.com/accounts/answer/61416?hl=es">https://support.google.com/accounts/answer/61416?hl=es</a></p>
//           </li>
//           <li>
//             <p>Si utilitza Opera, en l'opció de Seguretat i Privadesa, podrà configurar el navegador. Per a saber-ne més, visite <a target="_blank" href="http://help.opera.com/Windows/11.50/es-ES/cookies.html">http://help.opera.com/Windows/11.50/es-ES/cookies.html</a></p>
//           </li>
//           <li>
//             <p>. Si utilitza Safari trobarà l'opció en el menú Preferències/Privadesa. Més informació en: <a target="_blank" href="http://support.apple.com/kb/HT1677?viewlocale=es_ES">http://support.apple.com/kb/HT1677?viewlocale=es_ES</a></p>
//           </li>
//         </ol>
//       </li>
//     </ol>
//     <p>Última actualización: 21 de enero de 2019.</p>
//   </div>
//   </div></div>`,
// };
