export const timesUnitIds = {
  annually: 'Annually',
  semiannually: 'Semi-annually',
  quarterly: 'Quarterly',
  bimonthly: 'Bimonthly',
};

export const timesUnitLenthIds = {
  1: 'Annually',
  2: 'Semi-annually',
  3: 'Quarterly',
  4: 'Bimonthly',
};

export const timesUnit = [
  {
    name: timesUnitIds.quarterly,
    periods: [
      { name: 'Quarter 1', id: 'quarter1' },
      { name: 'Quarter 2', id: 'quarter2' },
      { name: 'Quarter 3', id: 'quarter3' },
    ],
  },
  {
    name: timesUnitIds.bimonthly,
    periods: [
      { name: 'Bimester 1', id: 'bimester1' },
      { name: 'Bimester 2', id: 'bimester2' },
      { name: 'Bimester 3', id: 'bimester3' },
      { name: 'Bimester 4', id: 'bimester4' },
    ],
  },
  {
    name: timesUnitIds.semiannually,
    periods: [
      { name: 'Semester 1', id: 'semester1' },
      { name: 'Semester 2', id: 'semester2' },
    ],
  },
  { name: timesUnitIds.annually, periods: [{ name: 'School year', id: 'Annually' }] },
];

export const LTI_CONTENT_TYPES = {
  AUDIO: 'audio',
  DOCUMENT: 'document',
  VIDEO: 'video',
  HTML: 'html',
  PDF: 'pdf',
  PPT: 'ppt',
  EXCEL: 'excel',
  DOCX: 'docx',
};

export const typeContentsIcons = {
  CTTY_00: 'document',
  CTTY_01: 'pdf',
  CTTY_03: 'video',
  CTTY_04: 'audio',
  CTTY_05: 'pdf',
  CTTY_06: 'link-content',
  CTTY_07: 'image',
  CTTY_08: 'task2',
  CTTY_09: 'questionary',
  CTTY_10: 'task2',
  CTTY_13: 'html-interactive',
  CTTY_14: 'task2',
  CTTY_12: 'pdf',
  CTTY_20: 'xapi',
  CTTY_VIDEOLESSON: 'videolesson',
  questionary: 'questionary',
  test: 'questionary',
  videolesson: 'video-camera',
};

export const typeContentsCovers = {
  CTTY_00: 'cover-card-document',
  CTTY_01: 'cover-card-doc',
  CTTY_03: 'cover-card-video',
  CTTY_04: 'cover-card-audio',
  CTTY_05: 'cover-card-doc',
  CTTY_06: 'cover-card-link',
  CTTY_08: 'cover-card-task',
  CTTY_09: 'cover-card-quizz',
  CTTY_10: 'cover-card-task',
  // CTTY_13: 'cover-card-html',
  CTTY_14: 'cover-card-task',
  CTTY_12: 'cover-card-doc',
  CTTY_20: 'cover-card-quizz',
  CTTY_VIDEOLESSON: 'cover-card-videolesson',
};

export const CONTENT_TYPES = {
  AUDIO: 'CTTY_04',
  VIDEO: 'CTTY_03',
  LINK: 'CTTY_06',
  IMAGE: 'CTTY_07',
  MINT: 'CTTY_MINTBLOCK',
  OFFICE: 'CTTY_12',
  PDF: 'CTTY_05',
  TEST: 'CTTY_09',
  QUESTION: 'CTTY_14',
  VIDEOLESSON: 'CTTY_VIDEOLESSON',
  XAPI: 'CTTY_20',
  SCORM: 'CTTY_10',
  LTI: 'CTTY_LTI',
  ZIP: 'CTTY_13',
  FIXED_LAYOUT: 'CTTY_01',
};

export const CONTENT_TYPES_NAMES = {
  CTTY_01: 'Document',
  CTTY_03: 'Video',
  CTTY_04: 'Audio',
  CTTY_05: 'PDF',
  CTTY_06: 'Link',
  CTTY_07: 'Image',
  CTTY_08: 'Task',
  CTTY_09: 'Quizz',
  CTTY_10: 'Scorm',
  CTTY_13: 'Interactive',
  CTTY_12: 'PDF',
  CTTY_20: 'XAPI',
  CTTY_VIDEOLESSON: 'Videolesson',
  CTTY_14: 'Task',
  CTTY_LTI: 'LTI Content',
  TEST: 'Quizz',
  FIXED_LAYOUT: 'Fixed Layout',
  RETRO_BOOK: 'Retro book',
  MINT_LESSON: 'Mint Lesson',
};

export function getIdTypeContent(id) {
  let idValue = '';
  for (let type in typeContentsIcons) {
    if (typeContentsIcons[type] === id) {
      idValue = type;
      break;
    }
  }

  return idValue;
}

export const lessonType = {
  online: {
    text: 'Online',
    icon: 'online',
    value: 'online',
  },
  onsite: {
    text: 'Onsite',
    icon: 'onsite',
    value: 'onsite',
  },
};

export const weekdays = [
  { name: 'monday', id: 'monday' },
  { name: 'tuesday', id: 'tuesday' },
  { name: 'wednesday', id: 'wednesday' },
  { name: 'thursday', id: 'thursday' },
  { name: 'friday', id: 'friday' },
  { name: 'saturday', id: 'saturday' },
  { name: 'sunday', id: 'sunday' },
];

export const devicesIcon = {
  desktop: 'desktop',
  tablet: 'tablet',
  smartphone: 'device',
};

export const licenseType = {
  personal: 'LICENSE_PERSONAL',
  school: 'SCHOOL_GROUP',
};

export const role = {
  student: 'R01',
  teacher: 'R02',
};

export const studentStreamType = {
  private: 'private',
  onlyComment: 'onlyComment',
  postAndComment: 'postAndComment',
};

export const EMBED_FROM = {
  program: 'program',
  unit: 'unit',
  lesson: 'lesson',
  activity: 'activity',
};

export const CLIENT = {
  TANGERINE: 'TANGERINE',
  EDELVIVES: 'EDELVIVES_DIGITAL_PLUS',
  MACMILLAN: 'MACMILLAN',
  SANTILLANA_ALFA: 'SANTILLANA_ALFA',
  SANTILLANA_UNOI: 'SANTILLANA_UNOI',
  SANTILLANA_INTEGRATIONS: '9d9f77ae-6747-42a5-a5f1-1c67e2a5a878',
  GOTITBYME: 'GOTITBYME',
  MASTER: 'master',
  MASTER_DEMO: 'b372c122-0bda-4546-8b38-f023a9edf8b3',
  SANTILLANA_DEMO: '71bfaecf-c8d8-40dd-b297-35bca1acce82',
};

export const SANTILLANA_ALFA = 'proyectoalfa.santillana';

export const BANNER_FORM_IMAGE = {
  GOTITBYME: 'https://gotitbyme-prod-content.s3.eu-central-1.amazonaws.com/c3975d40-0dea-11ef-bb6e-8fc4bd3c83f2/Banner%20Crear%20Clase.png',
};

export const KANBAN_BACKGROUND_IMAGE = {
  GOTITBYME: 'https://gotitbyme-prod-content.s3.eu-central-1.amazonaws.com/c3f279e0-66b0-11ef-8e06-057a2779e67f/kanban-bg.png',
};

export const LESSON_TYPES_IDS = {
  TEACHER: 'teacher',
  MINT: 'mint',
  POWER: 'power',
};

export const LESSON_TYPES = [
  {
    name: 'lesson:Classic',
    id: LESSON_TYPES_IDS.TEACHER,
  },
  {
    name: 'lesson:Mint',
    id: LESSON_TYPES_IDS.MINT,
  },
  {
    name: 'lesson:Power',
    id: LESSON_TYPES_IDS.POWER,
  },
];

export const AMPLITUDE_EVENT = {
  START: 'START',
  SEND_MESSAGE: 'SEND MESSAGE',
  SEND_COMMENT: 'SEND COMMENT',
  PROJECT_LESSON: 'PROJECT LESSON',
  REMOTE_DEVICE_CONNECTED: 'REMOTE DEVICE CONNECTED',
  PERSONALIZE_PROGRAM: 'PERSONALIZE PROGRAM',
  EDIT_EDITORIAL_LESSON: 'EDIT EDITORIAL LESSON',
  CREATE_NEW_LESSON: 'CREATE NEW LESSON',
  CREATE_NEW_UNIT: 'CREATE NEW UNIT',
  REVIEW_TASK: 'REVIEW TASK',
  ASSESSMENT_FEEDBACK: 'ASSESSMENT FEEDBACK',
  OPEN_CONTENT: 'OPEN CONTENT',
  SET_DELIVER_DATE: 'SET DELIVER DATE',
  OPEN_GRADES: 'OPEN GRADES',
  MINT_HIGHLIGHT: 'MINT HIGHLIGHT',
  SHARE_GOOGLE_CLASSROOM: 'SHARE ON GOOGLE CLASSROOM',
  ANSWER_ACTIVITY: 'ANSWER ACTIVITY',
  DOWNLOAD_DESKTOP_APP: 'DOWNLOAD DESKTOP APP',
  OPEN_TODO: 'OPEN TODO',
  OPEN_IMMERSIVE_READER: 'OPEN IMMERSIVE READER',
  OPEN_LESSON: 'OPEN LESSON',
  HIGHLIGHTER: 'HIGHLIGHTER',
  STUDENTS_VIEW_RESULTS: 'STUDENTS VIEW RESULTS',
  HOME_LIBRARY: 'HOME LIBRARY',
  HOME_CLASSROOM: 'HOME CLASSROOM',
  OPEN_COURSE: 'OPEN COURSE',
  CHANGE_LANGUAGE: 'CHANGE LANGUAGE',
  CREATE_MATERIAL: 'CREATE MATERIAL',
  CREATE_ACTIVITY: 'CREATE ACTIVITY',
  ADD_ACTIVITY_FROM_LIBRARY: 'ADD ACTIVITY FROM LIBRARY',
  ADD_MINT_BLOCK: 'ADD MINT BLOCK',
  CREATE_VIDEOLESSON: 'CREATE VIDEOLESSON',
  CREATE_EVALUATION: 'CREATE EVALUATION',
  CREATE_QUIZ: 'CREATE QUIZ',
  CREATE_TASK: 'CREATE TASK',
  PAGINATION_OPEN_WIDGET: 'OPEN PAGINATION WIDGET',
  PAGINATION_GO_TO_PAGE: 'PAGINATION GO TO PAGE',
  PAGINATION_OPEN_BOOK: 'PAGINATION OPEN BOOK',
  DO_TASK: 'DO TASK',
  OPEN_USERS_CLASSROOM: 'OPEN USERS CLASSROOM',
  IMPORT_FROM_CLASSROOM: 'IMPORT FROM CLASSROOM',
  CLICK_IMPORT_FROM_CLASSROOM: 'CLICK IMPORT FROM CLASSROOM',
  CREATE_EMBED_LINK: 'CREATE EMBED LINK',
  CREATE_LINK: 'CREATE LINK',
  CREATE_FEEDBACK_AI: 'CREATE FEEDBACK AI',
  CREATE_ACTIVITY_AI: 'CREATE ACTIVITY AI',
  CREATE_MODEL_AI: 'CREATE MODEL AI',
  SHOW_LESSON_ITEM: 'SHOW LESSON ITEM',
  OPEN_HOMEPAGE: 'OPEN HOMEPAGE',
};

export const APP_LINKS = {
  WINDOWS: 'https://releases-apps.s3.us-east-1.amazonaws.com/edv-electron/Edelvives-Digital-Plus+Setup+1.4.2.exe',
  LINUX: 'https://releases-apps.s3.us-east-1.amazonaws.com/edv-electron/Edelvives-Digital-Plus+Setup+1.4.2.exe',
  MAC: 'https://releases-apps.s3.us-east-1.amazonaws.com/edv-electron/Edelvives-Digital-Plus-1.4.2.dmg',
};

export const STUDENT_AGES = {
  ey1: 3,
  ey2: 4,
  ey4: 6,
  ey5: 7,
  ey6: 8,
  ey7: 9,
  ey8: 10,
  ey9: 11,
  ey10: 12,
  ey11: 13,
  ey12: 14,
  ey13: 15,
  ey14: 16,
  ey15: 17,
  'ey-es-01': 3,
  'ey-es-02': 4,
  'ey-es-03': 5,
  'ey-es-04': 6,
  'ey-es-05': 7,
  'ey-es-06': 8,
  'ey-es-07': 9,
  'ey-es-08': 10,
  'ey-es-09': 11,
  'ey-es-10': 12,
  'ey-es-11': 13,
  'ey-es-12': 14,
  'ey-es-13': 15,
  'ey-es-90': 16,
  'ey-es-91': 17,
  'ey-ar-01': 3,
  'ey-ar-02': 4,
  'ey-ar-03': 5,
  'ey-ar-04': 6,
  'ey-ar-05': 7,
  'ey-ar-06': 8,
  'ey-ar-07': 9,
  'ey-ar-08': 10,
  'ey-ar-09': 11,
  'ey-ar-10': 12,
  'ey-ar-11': 13,
  'ey-ar-12': 14,
  'ey-ar-13': 15,
  'ey-ar-90': 16,
  'ey-ar-91': 17,
};

export const AI_ASSISTANT_SCOPE = {
  HOME: 'HOME',
  COURSE: 'COURSE',
  LESSON: 'LESSON',
  XAPI: 'XAPI',
};

export const AI_ASSISTANT_DIALOG_TYPE = {
  TEXT: 'TEXT',
  QUESTION: 'QUESTION',
};
export const VIDEOLESSON_EMPTY = {
  id: '',
  url: '',
  thumbnailUrl: '',
  duration: '',
  title: '',
  type: '',
  sections: [],
  references: [],
  questions: {},
  preferences: { allowSkipAhead: true, allowRepeatQuestion: true },
};

export const FEATURE_FLAGS = {
  SHOW_IMMERSIVE_READER: 'showImmersiveReader',
  SHOW_LEMONADE_FEEDBACK_GENERATOR: 'showLemonadeFeedbackGenerator',
  QUESTION_GENERATED_WITH_AI: 'questionsGeneratedWithAi',
  SHOW_CREATE_VIDEOLESSON: 'showCreateVideolesson',
  FEEDBACK_GENERATED_WITH_AI: 'feedbackGeneratedWithAi',
  SHOW_CUSTOM_AVATAR: 'showPersonalizeAvatar',
  IS_GAMIFIED: 'isGamified',
  ALLOW_MOVE_SESSIONS: 'allowMoveCalendarSessions',
  SHOW_POWER_LESSON: 'showPowerLesson',
  SHOW_SELECT_TYPE_LESSON: 'showSelectTypeLesson',
};

export const TNG_TEMPLATES = {
  VANILLA: 'vanilla',
  UNOI: 'unoi',
  COMPONENTS: 'components',
  STEAM: 'steam',
  CUSTOMIZABLE: 'personalizable',
};

export const MAP_TYPES = {
  SPAIN: 'map_es',
};

export const systemRequirements = {
  es: '<div class="login-form__system-requirements-text"><ol><h3><li> Plataforma web (navegadores soportados)</h3> <ul><li>- Microsoft Edge (v111/v112)</li><li>- Firefox (v110/v111)</li><li>- Chrome (v111.0.5563.146/v111.0.5563.147)</li><li>- Safari (v15/v16)</li></ul></li></ol><ol><li><strong>iOS:</strong> <ul><li> Versión mínima: 15.4 </li></ul> </li> <li><strong>Android:</strong>  <ul><li> Versión mínima: 5.0 </li></ul></li></li></ul></li></ol></div>',
  en: '<div class="login-form__system-requirements-text"><ol><h3><li> Web Application (supported browsers)</h3> <ul><li>- Microsoft Edge (v111/v112)</li><li>- Firefox (v110/v111)</li><li>- Chrome (v111.0.5563.146/v111.0.5563.147)</li><li>- Safari (v15/v16)</li></ul></li></ol><ol><li><strong>iOS:</strong> <ul><li> Minimum requirements: 15.4 version </li></ul> </li> <li><strong>Android:</strong>  <ul><li> Minimum requirements: 5.0 version </li></ul></li></li></ul></li></ol></div>',
};

export const systemRequirementsWithApps = {
  es: '<div class="login-form__system-requirements-text"><ol><h3><li> Plataforma web (navegadores soportados)</h3> <ul><li>- Microsoft Edge (v111/v112)</li><li>- Firefox (v110/v111)</li><li>- Chrome (v111.0.5563.146/v111.0.5563.147)</li><li>- Safari (v15/v16)</li></ul></li></ol><ol><h3><li>APPS</h3><ul><li><strong>Escritorio</strong> <ul> <li> <strong> - Mac:</strong> <ul><li> Versión mínima: macOS 10.12 </li><li> Memoria RAM: 4GB </li></ul></li><li><strong>- Windows:</strong> <ul><li> Versión mínima: Windows 7 (deseable 10) </li> <li> Memoria RAM: 4GB </li></ul></li><li><strong>- Linux:</strong> <ul><li> Versión mínima: Ubuntu 14.04.4 LTS (deseable Ubuntu 14.04.5 LTS) </li><li> Memoria RAM: 4GB </li></ul></li></ul><li><strong>iOS:</strong> <ul><li> Versión mínima: 15.4 </li></ul> </li> <li><strong>Android:</strong>  <ul><li> Versión mínima: 5.0 </li></ul></li></li></ul></li></ol></div>',
  en: '<div class="login-form__system-requirements-text"><ol><h3><li> Web Application (supported browsers)</h3> <ul><li>- Microsoft Edge (v111/v112)</li><li>- Firefox (v110/v111)</li><li>- Chrome (v111.0.5563.146/v111.0.5563.147)</li><li>- Safari (v15/v16)</li></ul></li></ol><ol><h3><li>APPS</h3><ul><li><strong>Desktop</strong> <ul> <li> <strong> - Mac:</strong> <ul><li> Minimum requirements: macOS 10.12 </li><li> RAM: 4GB </li></ul></li><li><strong>- Windows:</strong> <ul><li> Minimum requirements: Windows 7 (deseable 10) </li> <li> RAM: 4GB </li></ul></li><li><strong>- Linux:</strong> <ul><li> Minimum requirements: Ubuntu 14.04.4 LTS (deseable Ubuntu 14.04.5 LTS) </li><li> RAM: 4GB </li></ul></li></ul><li><strong>iOS:</strong> <ul><li> Minimum requirements: 15.4 version </li></ul> </li> <li><strong>Android:</strong>  <ul><li> Minimum requirements: 5.0 version </li></ul></li></li></ul></li></ol></div>',
};
